import { dateConfig } from '@fallonsolutions/date'
import { find } from 'lodash-es'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import anzacDayPng from '../static/images/anzac-day.png'
import biggestMorningTeaPng from '../static/images/biggest-morning-tea.png'
import cincoDeMayoPng from '../static/images/cinco-de-mayo.png'
import earthDayPng from '../static/images/earth-day.png'
import easterPng from '../static/images/easter.png'
import ekkaPng from '../static/images/ekka.png'
import halloweenPng from '../static/images/halloween.png'
import independenceDayPng from '../static/images/independence-day.png'
import newYearsPng from '../static/images/newyears.png'
import saintPatricksDayPng from '../static/images/saint-patricks-day.png'
import santaHatSvg from '../static/images/santa-hat.svg'
import starWarsSvg from '../static/images/star-wars.svg'
import talkLikeAPirateDayPng from '../static/images/talk-like-a-pirate-day.png'
import valentinesDayPng from '../static/images/valentines-day.png'
import worldEmojiDayPng from '../static/images/world-emoji-day.png'
import worldTurtleDayPng from '../static/images/world-turtle-day.png'
import createPersistedState from '../use-persisted-state'

const useDecorations = createPersistedState<boolean>('decorations')

export interface EventTheme {
  id: string
  logo: any
  title?: string
  decorations?: {
    enabled: boolean
    className: string
  }
  confetti?: {
    enabled: boolean
    colors?: string[]
  }
}

export const useEventTheme = () => {
  const [eventThemeId] = useQueryParam('eventTheme', StringParam, { updateType: 'replaceIn' })

  const [decorations, setDecorations] = useDecorations(false)
  const [confettiSource, setConfettiSource] = useState<any>(undefined)

  const now = DateTime.now().setZone(dateConfig.defaultTimezone)
  const eventTheme = eventThemeId ? find(eventThemes, { id: eventThemeId }) : getEventThemeForDate(now)

  return { eventTheme, decorations, confettiSource, setDecorations, setConfettiSource }
}

const eventThemes: EventTheme[] = [
  {
    id: 'easter',
    logo: easterPng,
    confetti: { enabled: true },
    decorations: { enabled: true, className: 'easter' }
  },
  {
    id: 'anzac-day',
    logo: anzacDayPng
  },
  {
    id: 'halloween',
    logo: halloweenPng
  },
  {
    id: 'saint-patricks-day',
    logo: saintPatricksDayPng,
    confetti: { enabled: true, colors: ['#009B47', '#FF7802', '#FFFFFF'] },
    decorations: { enabled: true, className: 'sainttpatricksday' }
  },
  {
    id: 'valentines-day',
    logo: valentinesDayPng,
    confetti: { enabled: true, colors: [] }
  },
  {
    id: 'new-years',
    logo: newYearsPng,
    confetti: { enabled: true },
    decorations: { enabled: true, className: 'christmas' }
  },
  {
    id: 'christmas',
    logo: santaHatSvg,
    decorations: { enabled: true, className: 'christmas' },
    confetti: { enabled: true, colors: ['#285B52', '#58B69B', '#F2F3F8', '#D23943', '#621622'] }
  },
  {
    id: 'ekka',
    logo: ekkaPng
  },
  {
    id: 'star-wars-day',
    logo: starWarsSvg
  },
  {
    id: 'talk-like-a-pirate-day',
    logo: talkLikeAPirateDayPng
  },
  {
    id: 'independence-day',
    logo: independenceDayPng,
    confetti: { enabled: true, colors: ['#E2384A', '#ffffff', '#1C62A7'] }
  },
  {
    id: 'earth-day',
    logo: earthDayPng
  },
  {
    id: 'world-turtle-day',
    title: 'Happy World Turtle Day! 🐢',
    logo: worldTurtleDayPng
  },
  {
    id: 'world-emoji-day',
    logo: worldEmojiDayPng
  },
  {
    id: 'biggest-morning-tea',
    logo: biggestMorningTeaPng
  },
  {
    id: 'cinco-de-mayo',
    logo: cincoDeMayoPng
  }
]

const getEventThemeForDate = (date: DateTime): EventTheme | undefined => {
  const isNewYears = (date.month === 12 && date.day === 31) || (date.month === 1 && date.day === 1)
  const isChristmas = date.month === 12
  const isValentinesDay = date.month === 2 && date.day === 14
  const isAnzacDay = date.month === 4 && date.day >= 22 && date.day <= 26
  const isHalloween = date.month === 11 && date.day === 31
  const isSaintPatricksDay = date.month === 3 && date.day === 17
  const isEaster = date.month === 4 && date.day >= 17 && date.day <= 21
  const isEkkaWeek = date.month === 8 && date.day >= 12 && date.day <= 20
  const isIndependenceDay = date.month === 7 && date.day === 5
  const isStarWarsDay = date.month === 5 && date.day === 3
  const isTalkLikeAPirateDay = date.month === 9 && date.day === 19
  const isEarthDay = date.month === 4 && date.day === 22
  const isWorldTurtleDay = date.month === 5 && date.day === 23
  const isWorldEmojiDay = date.month === 7 && date.day === 17
  const isBiggestMorningTeaDay = date.month === 5 && date.day === 23
  const isCincoDeMayo = date.month === 5 && date.day === 6

  if (isEaster) {
    return find(eventThemes, { id: 'easter' })
  } else if (isCincoDeMayo) {
    return find(eventThemes, { id: 'cinco-de-mayo' })
  } else if (isStarWarsDay) {
    return find(eventThemes, { id: 'star-wars-day' })
  } else if (isTalkLikeAPirateDay) {
    return find(eventThemes, { id: 'talk-like-a-pirate-day' })
  } else if (isEarthDay) {
    return find(eventThemes, { id: 'earth-day' })
  } else if (isWorldTurtleDay) {
    return find(eventThemes, { id: 'world-turtle-day' })
  } else if (isWorldEmojiDay) {
    return find(eventThemes, { id: 'world-emoji-day' })
  } else if (isBiggestMorningTeaDay) {
    return find(eventThemes, { id: 'biggest-morning-tea' })
  } else if (isIndependenceDay) {
    return find(eventThemes, { id: 'independence-day' })
  } else if (isAnzacDay) {
    return find(eventThemes, { id: 'anzac-day' })
  } else if (isHalloween) {
    return find(eventThemes, { id: 'halloween' })
  } else if (isSaintPatricksDay) {
    return find(eventThemes, { id: 'saint-patricks-day' })
  } else if (isValentinesDay) {
    return find(eventThemes, { id: 'valentines-day' })
  } else if (isNewYears) {
    return find(eventThemes, { id: 'new-years' })
  } else if (isChristmas) {
    return find(eventThemes, { id: 'christmas' })
  } else if (isEkkaWeek) {
    return find(eventThemes, { id: 'ekka' })
  }
}
