import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import Dinero from 'dinero.js'
import { Currency, InvoiceFragment, InvoiceSource, InvoiceType } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { CreatePaymentButtonWrapper } from '../payments/create-payment-button'
import '../static/css/invoice.css'
import { CreateCreditNoteButton } from './create-credit-note-button'
import { CreateCustomerRefundButton } from './create-customer-refund-button'

interface InvoiceActionsProps {
  invoice: InvoiceFragment
}

export const InvoiceActions = (props: InvoiceActionsProps) => {
  const { invoice } = props

  const { userFragment } = useAuthenticated()

  const zero = Dinero({ amount: 0, currency: Currency.AUD })
  const paid = MoneyUtils.fromString(invoice?.paid?.total?.amount ?? '0.00')
  const refund = MoneyUtils.fromString(invoice?.refund?.total?.amount ?? '0.00')
  const balance = MoneyUtils.fromString(invoice.balance?.amount ?? '0.0')

  const hasOutstandingBalance = balance.greaterThan(zero)
  const hasRefundableAmount = paid.subtract(refund).greaterThan(zero)

  const isActionableInvoice = invoice.type === InvoiceType.Invoice && invoice.source === InvoiceSource.Platform
  const canCreatePayment = hasOutstandingBalance && userFragment.permissions?.platformInvoicingWeb === true
  const canCreateCreditNote = hasOutstandingBalance && userFragment.permissions?.creditNote === true
  const canCreateCustomerRefund = hasRefundableAmount && userFragment.permissions?.customerRefund === true

  const actionAvailable = canCreatePayment || canCreateCreditNote || canCreateCustomerRefund

  if (isActionableInvoice && actionAvailable) {
    return (
      <div style={{ paddingBottom: '12px' }}>
        <EuiHorizontalRule />
        <EuiFlexGroup
          gutterSize="s"
          justifyContent="spaceBetween"
          style={{ paddingRight: '12px', paddingLeft: '12px' }}
        >
          <EuiFlexItem grow={false}>{canCreatePayment && <CreatePaymentButtonWrapper invoice={invoice} />}</EuiFlexItem>
          <EuiFlexItem grow={false}>{canCreateCreditNote && <CreateCreditNoteButton invoice={invoice} />}</EuiFlexItem>
          <EuiFlexItem grow={false}>
            {canCreateCustomerRefund && <CreateCustomerRefundButton invoice={invoice} />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  } else {
    return <div></div>
  }
}
