/* eslint-disable max-lines */
import { useQuery } from '@apollo/client'
import {
  EuiAccordion,
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLoadingSpinner,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { BookingWindow } from '@fallonsolutions/appointment'
import { dateConfig } from '@fallonsolutions/date'
import { getTravelFeeId } from '@fallonsolutions/travel-fee'
import { JobTopicType } from '@fallonsolutions/types'
import { includes } from 'lodash-es'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
  AllDiscountsDocument,
  CustomerType,
  Discount,
  DiscountContextAvailability,
  GetTravelFeeDocument,
  JobType,
  LegacyBookingWindow,
  ServiceArea,
  TimeWindow,
  TradeType
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import { Callout } from '../../common/callout'
import { useAppConfig } from '../../config/app-config-provider'
import '../../static/css/markdown-v2.css'
import { TravelFee, getTravelFee } from '../helpers/travel-fee'
import { SewerIssue, StormwaterIssue } from '../topics/drain/action-topic-plumbing-drain-model'
import { SolarBatteryJobType } from '../topics/solar/action-topic-solar'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactRole, CustomerQualificationActionResult } from './action-customer-qualification'
import { JobClassificationActionResult } from './action-job-classification-types'
import { SelectJobTopicActionResult } from './action-select-job-topic'
import { JobCloseArmResponseView } from './job-close/job-close-arm-response-view'
import { DiscountView } from './job-close/job-close-discount-view'
import { SolarBatteryFaultRepairStatement } from './job-close/solar-job-close/solar-job-close'
import { WhyFallonsView } from './job-close/why-fallons-view'

export interface JobCloseActionInput extends BaseActionInput {
  customerQualification: CustomerQualificationActionResult
  jobClassification: JobClassificationActionResult
  jobTopic: SelectJobTopicActionResult
  existingMember: boolean
  afterHours: boolean
  reservationDate?: string
  serviceArea?: ServiceArea
  bookingWindow?: BookingWindow
  timeWindow?: TimeWindow
}

export interface JobCloseActionResult extends BaseActionResult {
  paymentTermsAdvised?: boolean
  membershipAdvised?: boolean
  covidCheckPassed?: boolean
  appliedDiscounts?: Discount[]
}

export const JobClose = (props: WorkflowActionProps<JobCloseActionInput, JobCloseActionResult>) => {
  const { userFragment } = useAuthenticated()
  const showNewTravelFee = userFragment.permissions?.developerFeatures === true

  const { input, result } = props
  const { jobClassification, customerQualification, jobTopic, existingMember, afterHours, serviceArea } = input ?? {}

  const tradeType = jobClassification.trade ?? TradeType.None
  const topicType = jobTopic.topic?.reference ?? JobTopicType.Other
  const jobType = jobClassification.type ?? JobType.None
  const customerTypeVal =
    (customerQualification.contactRole ?? ContactRole.HomeOwner) === ContactRole.HomeOwner
      ? CustomerType.Domestic
      : CustomerType.Commercial

  const travelFeeId = getTravelFeeId({
    tradeType,
    topicType,
    jobType,
    customerType: customerTypeVal,
    afterHours
  })
  const { data, loading, error } = useQuery(GetTravelFeeDocument, {
    variables: {
      input: {
        id: travelFeeId
      }
    },
    skip: !showNewTravelFee
  })
  const travelFeeEntity = data?.getTravelFee?.travelFee

  // TODO: migrate away from local logic to using getTravelFee query for job close
  const { customerType, type, trade } = jobClassification.topic ?? {}
  const { appliedDiscounts } = result ?? {}
  const isDomestic = customerQualification.contactRole === ContactRole.HomeOwner
  const reservationDate = input.reservationDate
    ? input.reservationDate
    : DateTime.now().setZone(dateConfig.defaultTimezone).toISO()

  const travelFee = getTravelFee({
    contactRole: customerQualification.contactRole ?? ContactRole.HomeOwner,
    existingMember,
    afterHours,
    trade: tradeType,
    jobType,
    jobTopicReference: topicType,
    travelFeeWaived: jobClassification.requirements?.travelFeeWaived ?? false,
    issueType: (jobClassification.topic as any).drainIssue,
    serviceArea,
    categoryType: jobClassification.category,
    topic: jobClassification.topic,
    appliedDiscounts
  })
  const labourRate = '$50 + GST'
  const minLabourCharge = '60 minutes'

  const getCloseTitle = (): string => {
    if (type === JobType.Callback) {
      return 'Revisit'
    } else if (type === JobType.Inspection) {
      return 'Inspection'
    } else if (type === JobType.Quote && !travelFee) {
      return 'Complimentary quote'
    } else {
      return existingMember && type === JobType.Service
        ? 'To refresh you on how we operate'
        : 'So I will run you through how we will operate'
    }
  }

  const isSolarQuote = (jobClassification?.topic as any)?.solarCategory === 'Quote'
  const isSolarBatteryFaultRepair =
    (jobClassification?.topic as any)?.solarBatteryJobType === SolarBatteryJobType.Repair

  // eslint-disable-next-line complexity
  const getClose = () => {
    // specific closes here before the defaults
    if (type === JobType.Callback) {
      return revisitClose
    } else if (trade === TradeType.Electrical && type === JobType.Quote && !travelFee) {
      return electricalQuoteClose()
    } else if (jobTopic.topic?.reference === JobTopicType.HotWaterInspection99) {
      return hotWaterInspection99Close(travelFee)
    } else if (jobTopic.topic?.reference === JobTopicType.SolarInspection && isDomestic) {
      return solarDomesticClose({ isHppInspection: false, travelFee })
    } else if (jobTopic.topic?.reference === JobTopicType.HomeEnergyAudit159) {
      return energyAudit159Close(travelFee)
    } else if (jobTopic.topic?.reference === JobTopicType.DrainClear99) {
      return drainClear99Close(travelFee)
    } else if (jobTopic.topic?.reference === JobTopicType.HVACConditionAndFilterCleanReport) {
      return hvacConditionAndFilterCleanReportClose()
    } else if (jobTopic.topic?.reference === JobTopicType.SwitchBoardInspection99) {
      return switchBoardInspection99Close(travelFee)
    } else if (jobTopic.topic?.reference === JobTopicType.SecurityInspection) {
      return securityInspectionClose(travelFee)
    } else if (trade === TradeType.Solar && type === JobType.Quote) {
      return solarQuoteClose // same for domestic and commercial
    } else if (trade === TradeType.Security) {
      if (type === JobType.Inspection) {
        return securityClose(isDomestic, travelFee)
      }
      if (type === JobType.Quote && !travelFee) {
        return securityQuoteClose()
      }
    } else if (customerType === CustomerType.Domestic && trade === TradeType.Solar && isSolarQuote) {
      return solarDomesticQuoteClose(travelFee)
    } else if (customerType === CustomerType.Domestic && trade === TradeType.Solar && isSolarBatteryFaultRepair) {
      return SolarBatteryFaultRepairStatement()
    } else if (trade === TradeType.HVAC && type === JobType.Service) {
      // if job topic is hvacRepair then use the new closes provided by Tristan
      const isRepair = jobTopic.topic?.reference === JobTopicType.HVACRepair
      const isDucted = jobClassification.category === 'Ducted'
      return isRepair
        ? hvacRepairClose({
            isDomestic,
            travelFee,
            existingMember,
            isDucted
          })
        : hvacServiceClose({
            isDomestic,
            travelFee,
            existingMember,
            isDucted
          })
    } else if (jobTopic.topic?.reference === JobTopicType.BackFlowTesting) {
      return backflowTestingClose({ travelFee, isDomestic })
    } else if (jobTopic?.topic?.reference === JobTopicType.SmokeAlarmInspection) {
      // 99 smoke alarm inspection
      return smokeAlarmInspectionClose({ travelFee })
    } else if (type === JobType.Inspection && trade) {
      return inspectionClose(trade, isDomestic, travelFee)
    } else if (type === JobType.Quote && trade === TradeType.HVAC) {
      return hvacQuoteClose({ travelFee, existingMember, isDomestic })
    } else if (trade === TradeType.Appliances) {
      return applianceClose({ travelFee, existingMember, customerType })
    } else if (jobTopic.topic?.reference === JobTopicType.Drains && customerType === CustomerType.Commercial) {
      // TODO: how we can we better access drainIssue for this case where it is needed to determine
      // close message?
      return drainsCommercialClose({
        contactRole: customerQualification.contactRole ?? ContactRole.Other,
        afterHours,
        issueType: (jobClassification.topic as any).drainIssue,
        travelFee,
        labourRate
      })
    }

    switch (customerType) {
      case CustomerType.Domestic:
        return domesticClose(existingMember, travelFee)
      case CustomerType.Commercial:
        return (
          <>
            {afterHours ? (
              <>
                {travelFee?.closingStatement ? (
                  <>{travelFee.closingStatement}</>
                ) : (
                  <>
                    <ul>
                      <li>Our qualified technician will come to the property, discuss the issue,</li>
                      <li>and provide you with all of the options</li>
                    </ul>
                    <ul>
                      {travelFee && (
                        <>
                          <li>
                            The {travelFee.label} is <strong>{travelFee.amount}</strong>
                          </li>
                          <li>
                            This price includes the <strong>service fee + 1 hour labour</strong> on site
                          </li>
                        </>
                      )}
                      {!travelFee && (
                        <ul>
                          <li>The travel fee is complimentary this time</li>
                        </ul>
                      )}
                    </ul>
                    <ul>
                      <li>We then charge labour in 30 minute increments afterward</li>
                      <li>We also on charge for materials and parking when required</li>
                    </ul>
                  </>
                )}
              </>
            ) : (
              <>
                <ul>
                  {travelFee?.closingStatement ? (
                    <>{travelFee.closingStatement}</>
                  ) : (
                    <>
                      {travelFee && (
                        <li>
                          Our qualified technician has a {travelFee.label} of <strong>{travelFee.amount}</strong>
                        </li>
                      )}
                      {!travelFee && (
                        <ul>
                          <li>The travel fee is complimentary this time</li>
                        </ul>
                      )}
                      <li>
                        Our labour fees are charged at <strong>{labourRate} for each 15 minutes</strong>
                      </li>
                      <li>
                        We do have a <strong>minimum labour rate of {minLabourCharge}</strong>
                      </li>
                      <li>and we also on charge for materials and parking when required.</li>
                    </>
                  )}
                </ul>
              </>
            )}
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <Callout type="script" title={getCloseTitle()} className="callout--flat-list">
      <EuiText>
        {showNewTravelFee && (
          <>
            {loading ? (
              <EuiLoadingSpinner />
            ) : error ? (
              <EuiCallOut color="danger">{error.message}</EuiCallOut>
            ) : travelFeeEntity ? (
              <>
                <EuiText size="xs" style={{ fontWeight: 600 }}>
                  NEW CLOSE
                </EuiText>
                <EuiSpacer size="s" />
                <EuiMarkdownFormat className="markdown-v2">{travelFeeEntity.descriptionFormatted}</EuiMarkdownFormat>
                {travelFeeEntity.disclaimerFormatted && (
                  <>
                    <EuiSpacer size="s" />
                    <EuiMarkdownFormat className="markdown-v2">{travelFeeEntity.disclaimerFormatted}</EuiMarkdownFormat>
                  </>
                )}
                <EuiSpacer />
                <EuiText size="xs" style={{ fontWeight: 600 }}>
                  OLD CLOSE
                </EuiText>
                <EuiSpacer size="s" />
              </>
            ) : (
              <EuiCallOut color="danger">Error loading job close</EuiCallOut>
            )}
          </>
        )}

        {getClose()}
        <ul>
          <li>
            <strong>Are you happy to book this in?</strong>
          </li>
        </ul>
      </EuiText>
    </Callout>
  )
}

export const JobCloseAction = (props: WorkflowActionProps<JobCloseActionInput, JobCloseActionResult>) => {
  const { input, onUpdate, result } = props
  const tenant = useAppConfig().tenant
  const newMemberEligibleForFreeTravelFee = tenant?.modules?.booking?.freeCustomerTravelFeeFlag === true
  const { actionCompleted } = result ?? {}
  const { customerQualification } = input
  const newCustomer = customerQualification.existingCustomer !== true
  const { data } = useQuery(AllDiscountsDocument)
  const discounts = (data?.allDiscounts?.discounts as Discount[]) ?? []
  const filteredDiscounts = discounts.filter((d) => d.context?.booking === DiscountContextAvailability.Available)
  const setActionCompleted = (actionCompleted: boolean) => onUpdate({ ...result, actionCompleted })
  const { existingMember, bookingWindow, timeWindow } = input ?? {}
  const type = input.jobClassification.type
  const euiAccordionButtonContent = () => {
    return (
      <>
        <EuiFlexGroup direction="row" alignItems="center" gutterSize="xs">
          <EuiFlexItem grow={false}>
            <EuiIcon type="plusInCircle" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText color="default">Apply discount</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    )
  }

  const handleDiscountChanged = (discounts: Discount[] | undefined) =>
    onUpdate({ ...result, appliedDiscounts: discounts })
  const [handleArmResponse, setHandleArmResponse] = useState<boolean>(false)
  const handleActionCompleted = () => {
    setActionCompleted(true)
    setHandleArmResponse(false)
  }

  const [whyFallonsWorkflowCompleted, setWhyFallonsWorkflowCompleted] = useState<boolean>(false)
  return (
    <WorkflowAction
      title="Job close"
      value={actionCompleted ? 'Completed' : undefined}
      onClickChange={() => setActionCompleted(false)}
      editable={input.editable}
    >
      {!whyFallonsWorkflowCompleted && (
        <WhyFallonsView workflowCompleted={() => setWhyFallonsWorkflowCompleted(true)} input={input} />
      )}
      {whyFallonsWorkflowCompleted && (
        <>
          {newCustomer && newMemberEligibleForFreeTravelFee && (
            <>
              <Callout
                type="warning"
                title="New customer may be eligible to have their travel fee waived. Click apply discount to find out more details"
              />
            </>
          )}
          <EuiAccordion
            id={'eui-according-job-close'}
            buttonContent={euiAccordionButtonContent()}
            arrowDisplay="none"
            isDisabled={filteredDiscounts.length === 0}
            buttonProps={{ css: { marginLeft: 'auto' } }}
          >
            <DiscountView handleDiscountChanged={handleDiscountChanged} discounts={filteredDiscounts} />
          </EuiAccordion>
          <EuiSpacer size="s" />
          <JobClose
            input={{
              customerQualification: input.customerQualification,
              jobTopic: input.jobTopic,
              bookingWindow: input.bookingWindow,
              timeWindow: input?.timeWindow,
              jobClassification: input.jobClassification,
              afterHours: input?.bookingWindow?.id === LegacyBookingWindow.AfterHours,
              existingMember: input.customerQualification?.existingMember ?? false,
              editable: input.editable,
              reservationDate: input?.reservationDate,
              serviceArea: input.serviceArea
            }}
            result={result}
            onUpdate={onUpdate}
          />
          <EuiSpacer size="s" />

          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiButton onClick={() => setActionCompleted(true)} fill={handleArmResponse !== true}>
                Yes
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={() => setHandleArmResponse(true)} fill={handleArmResponse === true}>
                No
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}

      {handleArmResponse && (
        <>
          <EuiSpacer size="l" />
          <JobCloseArmResponseView
            continueWithWorkflow={handleActionCompleted}
            bookingWindow={bookingWindow}
            timeWindow={timeWindow}
            jobType={type}
            existingMember={existingMember}
          />
        </>
      )}
      <EuiSpacer />
    </WorkflowAction>
  )
}

const hvacConditionAndFilterCleanReportClose = () => {
  return (
    <>
      <ul>
        <li>For just $99, we will come out to your home and do a filter clean and condition report.</li>
        <li>
          On arrival, our technician will undertake a comprehensive inspection of your unit, including checking for
          refrigerant leaks, inspecting the outdoor unit for vermin infestations, and identifying if faults exist.
        </li>
        <li>
          You’ll receive a detailed condition report before we commence the filter clean, giving you the option to
          consider additional deep cleaning services or repairs if needed.
        </li>
      </ul>
    </>
  )
}
const securityInspectionClose = (travelFee?: TravelFee) => {
  const amount = travelFee ? travelFee.amount : '$99'
  return (
    <>
      <ul>
        <li>
          For just <strong>{amount}</strong>, we will come out to your property and conduct a comprehensive check of
          your alarms system
        </li>
        <li>This includes a visual check and testing the alarm functionality.</li>
        <li>We will also replace the battery if needed.</li>
        <li>
          We will also provide a condition report, with recommendations that will be discussed with you and only
          undertaken with your permission.
        </li>
      </ul>
    </>
  )
}
const energyAudit159Close = (travelFee?: TravelFee) => {
  const amount = travelFee ? travelFee.amount : '$159'
  return (
    <>
      <ul>
        <li>
          For <strong>{amount}</strong>, our master Electrician will use our Fallon tried and tested Home energy audit
          checklist to conduct a visual health check of your homes electrical system
        </li>
        <li>
          He will work through all the components of your home, checking and rating your lighting, power, Air
          Conditioning, solar, appliances so you know what is costing you money as well as giving recommendations along
          the way on how to save After completion
        </li>
        <li>
          you will receive a copy of the Audit with all the recommendations listed and an overall energy rating for your
          home
        </li>
      </ul>
    </>
  )
}

const drainClear99Close = (travelFee?: TravelFee) => {
  const amount = travelFee ? travelFee.amount : '$99'
  return (
    <>
      <ul>
        <li>
          For just <strong>{amount}</strong>, we will come out to your property and attempt to unblock your drains.
        </li>
        <li>This includes the use of a plunger or hand rods. </li>
        <li>
          If the work takes longer than 30 minutes, or needs more equipment, an upfront price will be provided for the
          additional works before we proceed.{' '}
        </li>
      </ul>
    </>
  )
}

const switchBoardInspection99Close = (travelFee?: TravelFee) => {
  const amount = travelFee ? travelFee.amount : '$99'
  return (
    <>
      <li>
        For Just <strong>{amount}</strong> we will come out to your property to conduct a comprehensive check of your
        switchboard.{' '}
      </li>
      <li>
        This includes testing the safety switches, re-tensioning and checking all connections, sealing holes, and
        checking compliance to current Australian standards.{' '}
      </li>
      <li>
        We will also provide a condition report, with recommendations that will be discussed with you and only
        undertaken with your permission.{' '}
      </li>
    </>
  )
}

const hotWaterInspection99Close = (travelFee?: TravelFee) => {
  const amount = travelFee ? travelFee.amount : '$99'
  return (
    <>
      <ul>
        <li>
          {' '}
          I will send out a qualified technician to site, who will conduct a comprehensive check of your hot water
          system{' '}
        </li>
        <li>
          {' '}
          The <strong>{amount}</strong> check includes: checking the overall condition of your Hot Water system, testing
          the valves and the relief drain for blockages.{' '}
        </li>
        <li>
          {' '}
          They will also test the water temperature and inspect the pipework. Any recommendations or extra work
          required{' '}
        </li>
        <li>
          {' '}
          will be discussed with you prior and any work will only be undertaken with your permission. Are you happy to
          book this in?
        </li>
      </ul>
    </>
  )
}

const smokeAlarmInspectionClose = ({ travelFee }: { travelFee?: TravelFee }) => {
  return (
    <>
      <ul>
        <li>
          I will send out a qualified technician to your home who will conduct a comprehensive check of your smoke
          alarms for <strong>{travelFee?.amount ?? '$99'} </strong>
        </li>
        <li>They will look over all of your smoke alarms to ensure they’re operating properly</li>
        <li>
          They will also conduct thorough checks to ensure the smoke alarms are up to date with current legislation and
          minimum standards.
        </li>
        <li>Upon completion of checks, they will supply you with a smoke alarm compliance certificate</li>
        <li>
          Any recommendations or extra work required will be discussed with you prior and any work will only be
          undertaken with your permission
        </li>
      </ul>
    </>
  )
}

const domesticClose = (existingMember: boolean, travelFee?: TravelFee) => {
  return (
    <>
      <ul>
        <li>Our qualified technician will come to your house, discuss the issue</li>
        <li>and provide you with solution options</li>
      </ul>
      {travelFee && (
        <ul>
          <li>
            There’s a {travelFee.label} of <strong>{travelFee.amount}</strong> to cover the cost of the technician
            travelling to your home
          </li>
        </ul>
      )}
      {!travelFee && (
        <ul>
          <li>The travel fee is complimentary this time</li>
        </ul>
      )}
      {existingMember === true ? (
        <ul>
          <li>The technician will provide you with the upfront cost before starting work,</li>
          <li>
            which <strong>includes your 15% member discount</strong>
          </li>
          <li>If this meets your approval, he’ll get the work underway</li>
        </ul>
      ) : (
        <ul>
          <li>The technician will provide you with the upfront cost, which,</li>
          <li>if you’re happy with, you can sign off on and he’ll get that work underway</li>
        </ul>
      )}
    </>
  )
}

const electricalQuoteClose = () => {
  return (
    <>
      <ul>
        <li>l’ll arrange for one of our qualified technicians to come out and discuss everything you require</li>
        <li>They will provide you with your best options and a no obligation complimentary quote.</li>
      </ul>
    </>
  )
}

const securityQuoteClose = () => {
  return (
    <>
      <li>I’ll arrange for one of our qualified technicians to come out and discuss everything you require</li>
      <li>They will provide you with your best options and a no obligation complimentary quote</li>
    </>
  )
}

const revisitClose = (
  <>
    <ul>
      <li>We stand by our workmanship and are happy to re-attend. We do however need to confirm with you that:</li>
      <li>If the fault is unrelated to work that we have completed, a fee will be chargeable.</li>
      <li>If it is our fault, there is no charge to resolve the issue.</li>
    </ul>
    <ul>
      <li>
        If there is further work required, the technicians will advise you of any charges prior to commencing any fault
        rectification. The decision will be yours.
      </li>
    </ul>
  </>
)

const securityClose = (isDomestic: boolean, travelFee?: TravelFee) => {
  return (
    <>
      {isDomestic ? (
        <>
          <ul>
            <li>
              I will send out a security technician to your home who will conduct a comprehensive{' '}
              {!travelFee?.amount ? `and complimentary` : ''} check of your security system.
            </li>
            <li>The technician will look over all of your equipment to ensure it's operating properly.</li>

            <li>
              They will also replace the alarm panel battery and provide a condition report on the system
              {travelFee ? ` for a ${travelFee.label} of ${travelFee.amount}` : `.`}
            </li>

            <li>
              Any recommendations or extra work required will be discussed with you prior and any work will only be
              undertaken with your permission.
            </li>
          </ul>
        </>
      ) : (
        <>
          <ul>
            <li>
              I will send out a security technician to your home who will conduct a comprehensive check of your security
              system.
            </li>
            <li>The technician will look over all of your equipment to ensure it's operating properly.</li>
          </ul>
          {travelFee?.closingStatement}
        </>
      )}
    </>
  )
}

const solarDomesticClose = ({ isHppInspection, travelFee }: { isHppInspection: boolean; travelFee?: TravelFee }) => {
  return (
    <ul>
      {isHppInspection ? (
        <li>
          We will come out to your property and conduct a complete a <strong>complimentary</strong> 26-point solar
          performance and safety check on the panels and the inverter
        </li>
      ) : (
        <li>
          For <strong>just {travelFee?.amount}</strong>, we will come out to your property and conduct a complete a
          26-point solar performance and safety check on the panels and the inverter
        </li>
      )}

      <li>
        Any recommendations or extra work required will be discussed with you prior and only undertaken with you
        permission
      </li>
      <li>This offer cannot be used in conjunction with any other offer</li>
    </ul>
  )
}

const solarDomesticQuoteClose = (travelFee: TravelFee | undefined) => {
  return (
    <>
      <ul>
        <li>
          {' '}
          {travelFee?.amount ? `For ${travelFee.amount}` : ''} I’ll arrange for one of our qualified technicians to come
          out and discuss everything you require.{' '}
        </li>
        <li>They will provide you with the best options for a replacement solar system.</li>
      </ul>
    </>
  )
}

const solarQuoteClose = (
  <ul>
    <li>I’ll arrange for one of our qualified technicians to come out and discuss everything you require</li>
    <li>They will provide you with your best options and a no obligation complimentary quote</li>
  </ul>
)

const hvacServiceClose = ({
  isDomestic,
  travelFee
}: {
  isDomestic: boolean
  travelFee?: TravelFee
  existingMember: boolean
  isDucted: boolean
}) => {
  return (
    <ul>
      {isDomestic ? (
        <>
          <li>
            <strong>(MUST BE READ VERBATIM)</strong>
          </li>
          <li>
            Our qualified technician will come to your home and discuss the options available to you dependent on the
            level of service required for your unit(s)
          </li>
          <li>If a FAULT BECOMES apparent a fault find fee will be provided to locate the issue</li>
          {travelFee && (
            <>
              <li style={{ marginTop: '15px', marginBottom: '15px' }}>(PAUSE)</li>
              <li>
                We also have a {travelFee.label} of <strong>{travelFee.amount} </strong> which is to cover the cost of
                our technician travelling out to your home
              </li>
            </>
          )}
        </>
      ) : (
        // commercial service
        <>
          {travelFee?.closingStatement ? (
            <>{travelFee.closingStatement}</>
          ) : (
            <>
              <li>
                Our qualified technician charges a {travelFee?.label} of <strong>{travelFee?.amount}</strong> and labour
                fees are charged at
                {` `}
                <strong>$32 + GST</strong> for each 15 minutes.
              </li>
              <li>
                We do have a minimum labour rate of 30 minutes, and we also charge for materials and parking (if
                required).
              </li>
            </>
          )}
        </>
      )}
    </ul>
  )
}

const hvacRepairClose = ({
  isDomestic,
  travelFee,
  existingMember,
  isDucted
}: {
  isDomestic: boolean
  travelFee?: TravelFee
  existingMember: boolean
  isDucted: boolean
}) => {
  return (
    <ul>
      <li>Our technician will come to site and investigate the problem.</li>
      <li>This may involve pulling the unit apart to diagnose the issue.</li>
      <li>
        We will be able to carry out repairs on the day if parts are available, if not then our office will send out a
        quote free of charge.
      </li>
      {travelFee && (
        <>
          <li>
            For us to attend site to diagnose the issues, we charge a <strong>{travelFee.amount}</strong>{' '}
            {travelFee.label}
          </li>

          {!isDucted && (
            <li>
              Please note that the initial charge of $289 is applicable for one unit, while any additional unit
              diagnostics will be charged at a reduced rate of $204.16 each.
            </li>
          )}
        </>
      )}
      {isDomestic && (
        <>
          {existingMember ? (
            <li>
              As a member you will get a 15% discount off the cost of the repairs and a 1 year extension on your
              membership
            </li>
          ) : (
            <>
              <li>
                We have a special offer where you will also be{' '}
                <strong>receiving a complimentary Home Protection Plan</strong>, valued at $60.
              </li>
              <li>This membership provides a 15% discount on this job and all other trades.</li>
            </>
          )}
        </>
      )}
    </ul>
  )
}

interface ApplianceCloseProps {
  existingMember: boolean
  customerType?: CustomerType
  travelFee?: TravelFee
}

const applianceClose = ({ travelFee, existingMember, customerType }: ApplianceCloseProps) => (
  <ul>
    <li>
      Our technician will come and investigate the problem, testing all components to ensure we fix it right the first
      time.
    </li>
    <li>This may involve pulling the appliance apart and identifying any faults. </li>
    {customerType === CustomerType.Commercial ? (
      <>
        <li>
          If all parts needed are on board, we do offer repairs the same day. These repairs will be quoted before
          proceeding.
        </li>
        <li>
          We may need to order parts and if this is the case <strong>our office will send you a quote first.</strong>{' '}
          {travelFee && (
            <>
              The appliance diagnostic fee is {travelFee.amount} <strong>{travelFee.label}</strong>
            </>
          )}
        </li>
      </>
    ) : (
      <>
        <li>
          We may need to <strong>order parts and if this is the case our office will send you a quote first.</strong>
        </li>
        {!existingMember && (
          <li>
            At the moment we have a special offer where you will receive a complementary Home Protection Plan which is
            normally $60.00. This membership provides a 15% discount on this job and all our other trades.
          </li>
        )}
        {travelFee && (
          <>
            <li>
              For us to attend site to diagnose the issues is{' '}
              <strong>{travelFee.amount} with repairs priced separately.</strong>
            </li>
          </>
        )}
        {existingMember && (
          <li>
            As a member you get{' '}
            <strong>15% discount off the cost of repairs and a 1 year extension on your membership </strong>
          </li>
        )}
      </>
    )}
  </ul>
)

interface DrainsCloseOpts {
  contactRole: ContactRole
  afterHours: boolean
  issueType?: StormwaterIssue | SewerIssue
  travelFee?: TravelFee
  labourRate?: string
}

const drainsCommercialClose = (opts: DrainsCloseOpts) => {
  const { afterHours, issueType, travelFee, labourRate } = opts
  const isPremiumCommercialIssue = includes(
    [StormwaterIssue.BlockedOrOverflowing, SewerIssue.BlockedOrOverflowing, SewerIssue.SlowDraining],
    issueType
  )
  const applyAfterHoursFee = afterHours || isPremiumCommercialIssue

  if (applyAfterHoursFee) {
    return (
      <>
        <ul>
          <li>
            Our qualified technician will come to your house, discuss the issue, and provide you with all of the
            options.
          </li>
          {travelFee && (
            <li>
              The {travelFee.label} is <strong>{travelFee.amount}</strong>.
            </li>
          )}
          <li>
            This price includes the service fee + 1 hour labour on site. We then charge{' '}
            {labourRate ? `${labourRate} in labour` : 'labour'} in 15 minute increments afterwards.
          </li>
          <li>We also on charge for materials and parking when required.</li>
        </ul>
      </>
    )
  } else {
    return (
      <>
        {travelFee?.closingStatement && <>{travelFee.closingStatement}</>}
        <ul>
          {travelFee && !travelFee.closingStatement && (
            <>
              <li>
                Our qualified technician has a {travelFee.label} of <strong>{travelFee.amount}</strong>.
              </li>
              <li>
                This will get them to site, where they will inspect the issue and provide you with upfront pricing to
                fix the issue.
              </li>
              <li>If you choose to accept that price then the technician will commence the work.</li>
            </>
          )}
        </ul>
      </>
    )
  }
}

interface QuoteCloseProps {
  isDomestic: boolean
  existingMember: boolean
  travelFee?: TravelFee
}
const hvacQuoteClose = ({ travelFee, isDomestic, existingMember }: QuoteCloseProps) => {
  return (
    <>
      {isDomestic ? (
        <>
          <ul>
            <li>
              {' '}
              Our qualified technician will come out to the site and assess your home’s heating and cooling needs.
            </li>
            <li>
              {' '}
              We’ll inspect the property, and ask you some questions to help you select the right system to suit your
              lifestyle and home layout
            </li>
            {travelFee?.amount ? (
              <>
                <li>
                  {' '}
                  There’s a travel fee of <strong>{travelFee?.amount ?? ''} </strong>to cover the cost of the technician
                  traveling to your home.
                </li>
                {existingMember ? (
                  <>
                    <li>
                      The technician will provide a quote, <strong>which includes your 15% member discount </strong> if
                      you proceed with quoted works the travel fee will come off the price
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      The technician will provide a quote, and if you proceed with any works the travel fee will come
                      off the price{' '}
                    </li>
                  </>
                )}
              </>
            ) : (
              <>
                <li>After inspecting your property, our technician will present you with a quote</li>
              </>
            )}
          </ul>
        </>
      ) : (
        <>
          <ul>
            <li>
              Our qualified technician will come out to the site and assess your home’s heating and cooling needs.
            </li>
            <li>
              We’ll inspect the property, and ask you some questions to help you select the right system to suit your
              lifestyle and home layout
            </li>
            <li>
              {' '}
              We charge a {travelFee?.label} <strong>{travelFee?.amount}</strong>
            </li>
            <li> Are you happy to book this in?</li>
          </ul>
        </>
      )}
    </>
  )
}

const inspectionClose = (trade: TradeType, isDomestic: boolean, travelFee?: TravelFee) => {
  switch (trade) {
    case TradeType.Electrical:
      return (
        <ul>
          <li>
            I will send out an electrician to your home who will conduct a your complimentary electrical inspection.
          </li>
          <li>They will check all things electrical in the house.</li>
          <li>
            If there is additional electrical work needed or requested, they will discuss those options with you on
            site.
          </li>
        </ul>
      )
    case TradeType.Plumbing:
      return (
        <ul>
          <li>I will send out a plumber to your home who will conduct a your complimentary plumbing inspection.</li>
          <li>They will check all things plumbing related in the house.</li>
          <li>
            If there is additional plumbing work needed or requested, they will discuss those options with you on site.
          </li>
        </ul>
      )
    case TradeType.Drains:
      return (
        <ul>
          <li>
            I will send out a drains tech to your home who will conduct a 30 min CCTV camera inspection of your drains.
          </li>
          <li>
            If there is additional plumbing work needed or requested, they will discuss those options with you on site.
          </li>
        </ul>
      )
    case TradeType.HVAC:
      return (
        <ul>
          <li>I'll run you through what the complimentary filter clean and condition report entails.</li>
          <li>
            Our technician will come to your house and clean the filters of all your AC units and provide you with a
            condition report for each one - all free of charge.
          </li>
          <li>
            The condition report gives you an indication of the level of servicing required -much like a mechanic would
            with your car.
          </li>
          <li>You will then be presented with some options and pricing for the service.</li>
          <li>If a fault becomes apparent, a fault-finding fee will also be provided.</li>
        </ul>
      )
    case TradeType.Security:
      return (
        <>
          <>
            <ul>
              <li>
                I will send out a security technician to your home who will conduct a comprehensive and complimentary
                check of your security system.
              </li>
              <li>The technician will look over all of your equipment to ensure it's operating properly.</li>
              <li>
                They will also replace the alarm panel battery and provide a condition report on the system for a{' '}
                {travelFee?.label} of <strong>{travelFee?.amount}</strong>.
              </li>
              <li>
                Any recommendations or extra work required will be discussed with you prior and any work will only be
                undertaken with your permission.
              </li>
            </ul>
          </>
        </>
      )

    case TradeType.Solar:
      return (
        <ul>
          <li>
            {travelFee?.amount ? `For ${travelFee.amount}` : ''} I will send out an electrician to your home who will
            conduct a complete {!travelFee?.amount ? 'complimentary' : ''} 26 point solar performance and safety check
            on the panels and the inverter.
          </li>
          <li>The technician will than discuss all findings with you.</li>
          <li>
            Any recommendations or extra work required will be discussed with you prior and only undertaken with your
            permission.
          </li>
        </ul>
      )

    default:
      return <></>
  }
}

interface backflowTestingCloseProps {
  travelFee?: TravelFee
  isDomestic: boolean
}

const backflowTestingClose = ({ travelFee, isDomestic }: backflowTestingCloseProps) => (
  <ul>
    {isDomestic && (
      <>
        <li>
          Our qualified technician will come to your house, discuss the issue and provide you with solution options.
        </li>
        <li>
          There’s a travel fee of <strong>{travelFee?.amount}</strong> to cover the cost of the technician traveling to
          your home.
        </li>
        <li>The Technician will provide you with an upfront cost before starting work.</li>
        <li>If this meets your approval, they’ll get the work underway</li>
      </>
    )}
    {!isDomestic && (
      <>
        <li>
          Our qualified technician will come to the site, discuss the issue and provide you with solution options.
        </li>
        <li>
          There is a travel fee of <strong>{travelFee?.amount}</strong> {travelFee?.label}
        </li>
        <li>Any additional valves needing testing will be $88 + GST</li>
      </>
    )}
  </ul>
)
