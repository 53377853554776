import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { isNil } from 'lodash-es'
import { JobSummaryAggregationFragment } from '../api/generated-types'
import { Metric, MetricType } from '../common/metrics'
import { useJobListMetricsTab } from '../common/persisted-state'
import VerticalDivider from '../common/vertical-divider'

interface JobListMetricsProps {
  summary?: JobSummaryAggregationFragment
}

enum JobListMetricsTab {
  Sales = 'Sales',
  Outcomes = 'Outcomes'
}

interface MetricTab {
  id: JobListMetricsTab
  name: string
  metrics: JSX.Element
}

/* eslint-disable-next-line complexity */
export const JobListMetrics = ({ summary }: JobListMetricsProps) => {
  const [selectedTabId, setSelectedTabId] = useJobListMetricsTab(JobListMetricsTab.Sales)
  const {
    count,
    sold,
    soldAverage,
    invoiced,
    invoicedAverage,
    optionsPresented,
    optionsPresentedAverage,
    optionsSold,
    optionsSoldAverage,
    optionSheetCount
  } = summary ?? {}

  const firstVisitCount = summary?.firstVisitCount ?? 0
  // const followOnVisitCount = summary?.followOnVisitCount ?? 0
  const futileCount = summary?.futileCount ?? 0
  const noSaleCount = summary?.noSaleCount ?? 0
  const offerOnlyCount = summary?.offerOnlyCount ?? 0
  const diagnosticOnlyCount = summary?.diagnosticOnlyCount ?? 0
  const conversionCount = summary?.conversionCount ?? 0
  // const toBeAttendedCount = summary?.toBeAttendedCount ?? 0

  const futilePercentage = futileCount > 0 && firstVisitCount > 0 ? (futileCount / firstVisitCount) * 100 : 0
  const noSalePercentage = noSaleCount > 0 && firstVisitCount > 0 ? (noSaleCount / firstVisitCount) * 100 : 0
  const offerOnlyPercentage = offerOnlyCount > 0 && firstVisitCount > 0 ? (offerOnlyCount / firstVisitCount) * 100 : 0
  const diagnosticOnlyPercentage =
    diagnosticOnlyCount > 0 && firstVisitCount > 0 ? (diagnosticOnlyCount / firstVisitCount) * 100 : 0
  const conversionPercentage =
    conversionCount > 0 && firstVisitCount > 0 ? (conversionCount / firstVisitCount) * 100 : 0

  const primaryMetric = (
    <>
      {!isNil(count) && !isNil(optionSheetCount) && (
        <EuiFlexItem grow={false}>
          <Metric
            primary={{ label: 'Jobs', value: count }}
            secondary={{
              label: 'Option Sheets',
              value: optionSheetCount
            }}
          />
        </EuiFlexItem>
      )}
    </>
  )

  const tabs: MetricTab[] = [
    {
      id: JobListMetricsTab.Sales,
      name: 'Sales and revenue',
      metrics: (
        <>
          {!isNil(sold) && !isNil(soldAverage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{
                  label: 'Total Sold',
                  value: sold,
                  type: MetricType.Currency
                }}
                secondary={{
                  label: 'Avg. per job',
                  value: soldAverage,
                  type: MetricType.Currency
                }}
              />
            </EuiFlexItem>
          )}
          {!isNil(invoiced) && !isNil(invoicedAverage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{
                  label: 'Total Invoiced',
                  value: invoiced,
                  type: MetricType.Currency
                }}
                secondary={{
                  label: 'Avg. per job',
                  value: invoicedAverage,
                  type: MetricType.Currency
                }}
              />
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <VerticalDivider />
          </EuiFlexItem>
          {!isNil(optionsPresented) && !isNil(optionsPresentedAverage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{
                  label: 'Avg. Options Presented',
                  value: optionsPresentedAverage,
                  type: MetricType.Float
                }}
                secondary={{
                  label: 'Total Options Presented',
                  value: optionsPresented
                }}
              />
            </EuiFlexItem>
          )}
          {!isNil(optionsSold) && !isNil(optionsSoldAverage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{
                  label: 'Avg. Options Sold',
                  value: optionsSoldAverage,
                  type: MetricType.Float
                }}
                secondary={{
                  label: 'Total Options Sold',
                  value: optionsSold
                }}
              />
            </EuiFlexItem>
          )}
        </>
      )
    },
    {
      id: JobListMetricsTab.Outcomes,
      name: 'Outcomes',
      metrics: (
        <>
          {!isNil(firstVisitCount) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'First visits', value: firstVisitCount }}
                // secondary={{ label: 'Follow on visits', value: followOnVisitCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(conversionPercentage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'Conversion', value: conversionPercentage, type: MetricType.Percentage }}
                secondary={{ label: 'Count', value: conversionCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(futilePercentage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'Futile', value: futilePercentage, type: MetricType.Percentage }}
                secondary={{ label: 'Count', value: futileCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(noSalePercentage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'No sale', value: noSalePercentage, type: MetricType.Percentage }}
                secondary={{ label: 'Count', value: noSaleCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(offerOnlyPercentage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'Offer only', value: offerOnlyPercentage, type: MetricType.Percentage }}
                secondary={{ label: 'Count', value: offerOnlyCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(diagnosticOnlyPercentage) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'Diag. only', value: diagnosticOnlyPercentage, type: MetricType.Percentage }}
                secondary={{ label: 'Count', value: diagnosticOnlyCount }}
              />
            </EuiFlexItem>
          )}
          {/* <EuiFlexItem grow={false}>
            <VerticalDivider />
          </EuiFlexItem>
          {!isNil(toBeAttendedCount) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'To attend', value: toBeAttendedCount }}
                // secondary={{ label: 'Cancelled', value: cancelledCount ?? 0 }}
              />
            </EuiFlexItem>
          )} */}
        </>
      )
    }
  ]

  const selectedTab = tabs.find((tab) => tab.id === selectedTabId)

  return (
    <div>
      <EuiFlexGroup gutterSize="s" style={{ marginBottom: '12px' }}>
        {tabs.map((tab) => (
          <EuiFlexItem
            key={tab.id}
            grow={false}
            className="small-text"
            onClick={() => setSelectedTabId(tab.id)}
            style={{
              marginRight: '12px',
              cursor: 'pointer',
              ...(tab.id === selectedTabId
                ? {
                    fontWeight: 'bold'
                  }
                : { textDecoration: 'underline' })
            }}
          >
            {tab.name.toUpperCase()}
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="s" className="spacing">
        {primaryMetric}
        <EuiFlexItem grow={false}>
          <VerticalDivider />
        </EuiFlexItem>
        {selectedTab?.metrics}
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <div className="metrics__note" style={{ paddingTop: '9px' }}>
        Note numbers include total for each job even when presented, sold or invoiced at appointments outside the search
        period.
      </div>
    </div>
  )
}
