import { EuiCodeBlock, EuiLink, EuiSpacer } from '@elastic/eui'
import { useAuthenticated } from '../auth/authenticated-context'
import { useLocalStorage } from '../common/use-local-storage'
import { jsonDump } from './utils'

interface RawJSONProps {
  data: any
}

const RawJSON = (props: RawJSONProps) => {
  const { data } = props
  const [expanded, setExpanded] = useLocalStorage('rawjson', false)
  const [isDev] = useLocalStorage('isDev', false)
  const rawJSON = jsonDump(data)
  const userFragment = useAuthenticated().userFragment

  const toggleCodeBlock = () => setExpanded(!expanded)
  const buttonLabel = `${expanded ? 'Hide' : 'Show'} raw data`

  const codeBlock = () => (
    <EuiCodeBlock language="json" isCopyable={true}>
      {rawJSON}
    </EuiCodeBlock>
  )

  return (
    <div>
      {userFragment.permissions?.developerFeatures === true && isDev && (
        <div>
          <EuiSpacer />
          <EuiLink onClick={toggleCodeBlock}>{buttonLabel}</EuiLink>
          <EuiSpacer size="s" />
          {expanded && codeBlock()}
        </div>
      )}
    </div>
  )
}

export default RawJSON
