import { ManualInvoiceReasonType } from '../api/generated-types'

export const getLabelForManualInvoiceReason = (reasonType: ManualInvoiceReasonType) => {
  switch (reasonType) {
    case ManualInvoiceReasonType.Cancellation:
      return 'One or more options cancelled'
    case ManualInvoiceReasonType.Correction:
      return 'Error in original invoice'
    case ManualInvoiceReasonType.Duplicate:
      return 'Duplicate invoice correction'
    case ManualInvoiceReasonType.NoOptionSheet:
      return 'No Option Sheet'
    case ManualInvoiceReasonType.PriorManualInvoice:
      return 'Prior manual invoice or credit note'
    case ManualInvoiceReasonType.Rebate:
      return 'Rebate (eg. Solar)'
    case ManualInvoiceReasonType.Refund:
      return 'Refund for customer'
    case ManualInvoiceReasonType.Variation:
      return 'Variation to original invoice'
    case ManualInvoiceReasonType.Other:
    default:
      return 'Other'
  }
}
