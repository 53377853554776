import { NetworkStatus, useMutation, useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui'
import { useState } from 'react'
import {
  CustomerLocationFragment,
  CustomerLocationStatusType,
  GetCustomerLocationsDocument,
  MembershipStatusType,
  UpdateCustomerLocationStatusDocument
} from '../api/generated-types'
import { Callout } from '../common/callout'
import { CustomerLocationStatusChangeModal } from '../customers/customer-location-table'
import { CustomerLocationSelectionTable } from './customer-location-selection'

type Location = CustomerLocationFragment

export interface CustomerLocationSelectorProps {
  customerId: string
  onSelect: (location: Location) => void
  onNew: () => void
}

const invalidStatuses = [
  CustomerLocationStatusType.Error,
  CustomerLocationStatusType.Previous,
  CustomerLocationStatusType.None
]

export const CustomerLocationSelector = (props: CustomerLocationSelectorProps) => {
  const { customerId, onSelect, onNew } = props

  const { data, loading, refetch, networkStatus } = useQuery(GetCustomerLocationsDocument, {
    variables: {
      id: customerId
    },
    nextFetchPolicy: 'cache-and-network'
  })

  const [selectedLocationForStatusChange, setSelectedLocationForStatusChange] = useState<
    CustomerLocationFragment | undefined
  >(undefined)

  const [updateLocationStatus] = useMutation(UpdateCustomerLocationStatusDocument, {
    refetchQueries: ['GetCustomerLocation', 'GetCustomer']
  })

  const handleOnSelect = (location: Location) => {
    onSelect(location)
  }

  const currentLocations: Location[] =
    data?.getCustomer?.locations?.filter((location) => !invalidStatuses.includes(location.status.status)) ?? []

  const previousLocations: Location[] =
    data?.getCustomer?.locations?.filter((location) => invalidStatuses.includes(location.status.status)) ?? []

  const expiredMemberships = data?.getCustomer?.memberships?.filter(
    (m) => m.status.status === MembershipStatusType.Expired
  )

  const onStatusChange = (locationId: string, status: CustomerLocationStatusType) => {
    updateLocationStatus({
      variables: {
        input: {
          locationId,
          status
        }
      }
    })
    setSelectedLocationForStatusChange(undefined)
  }

  const handleRefresh = () => {
    console.log('refetching')
    refetch({
      id: customerId
    })
  }

  const isLoading = loading || networkStatus === NetworkStatus.refetch

  return (
    <div style={{ display: 'block' }}>
      {loading ? (
        <EuiLoadingSpinner size="l" />
      ) : (
        <>
          {selectedLocationForStatusChange && (
            <CustomerLocationStatusChangeModal
              location={selectedLocationForStatusChange}
              closeModal={() => setSelectedLocationForStatusChange(undefined)}
              updateLocationStatus={onStatusChange}
            />
          )}
          <EuiFlexGroup>
            <EuiFlexItem grow={true}>
              <Callout type="note">Select existing customer location or create a new location</Callout>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty onClick={() => handleRefresh()}>Refresh</EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
          <CustomerLocationSelectionTable
            loading={isLoading}
            locations={currentLocations}
            previousLocations={previousLocations}
            onSelect={handleOnSelect}
            onNew={onNew}
            expiredMemberships={expiredMemberships}
            onChangeLocationStatus={(location) => setSelectedLocationForStatusChange(location)}
          />
        </>
      )}
    </div>
  )
}
