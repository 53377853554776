import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiRadio,
  EuiSpacer
} from '@elastic/eui'
import { useState } from 'react'
import {
  CustomerLocationFragment,
  CustomerLocationMembershipFragment,
  MembershipLevel,
  MembershipStatusType
} from '../api/generated-types'
import { Callout } from '../common/callout'
import PremiumMemberLogo from '../static/images/member-logo-premium.svg'
import StandardMemberLogo from '../static/images/member-logo-standard.svg'
import { ExpiredMembershipsAccordion } from '../workflow/actions/customer-qualifications/customer-expired-membership-accordin'
import { PreviousCustomerLocationsAccordion } from '../workflow/actions/customer-qualifications/customer-expired-memberships-accordion'

type Location = CustomerLocationFragment
type CustomerMembership = CustomerLocationMembershipFragment

interface CustomerLocationSelectionTableProps {
  locations: CustomerLocationFragment[]
  previousLocations?: CustomerLocationFragment[]
  loading: boolean
  onSelect: (location: Location) => void
  onNew: () => void
  expiredMemberships?: CustomerLocationMembershipFragment[]
  onChangeLocationStatus: (location: CustomerLocationFragment) => void
}

export const CustomerLocationSelectionTable = (props: CustomerLocationSelectionTableProps) => {
  const { loading, locations, previousLocations, onSelect, onNew, expiredMemberships, onChangeLocationStatus } = props

  const [highlightedLocation, setHighlightedLocation] = useState<CustomerLocationFragment | undefined>()
  const [newPropertyRequested, setNewPropertyRequested] = useState(false)
  const showMemberships = true

  const membershipsForSelectedLocation = highlightedLocation?.memberships ?? []

  const doesHighlightedLocationHaveAMembership =
    (membershipsForSelectedLocation?.filter((m) => m.status.status === MembershipStatusType.Active).length ?? 0) > 0

  return (
    <>
      <>
        {showMemberships && expiredMemberships && <ExpiredMembershipsAccordion memberships={expiredMemberships} />}
        {previousLocations && previousLocations.length > 0 && (
          <PreviousCustomerLocationsAccordion
            previousLocations={previousLocations}
            onChangeLocationStatus={onChangeLocationStatus}
          />
        )}
        <CustomerLocationTable
          locations={locations}
          onSelect={(location) => {
            setHighlightedLocation(location)
            setNewPropertyRequested(false)
          }}
          loading={loading}
          selectedLocation={highlightedLocation?.id}
          showMembership={showMemberships}
        />
        {!loading &&
          (locations.length ? (
            <>
              <div style={{ padding: '12px 6px', fontSize: '15px', color: '#999', fontWeight: 300 }}>OR</div>

              <div className="basic-table--minimal">
                <div className="euiTable euiTable--responsive">
                  <div className="euiTableRow euiTableRow-isClickable">
                    <div
                      className="euiTableRowCell"
                      onClick={() => {
                        setHighlightedLocation(undefined)
                        setNewPropertyRequested(true)
                      }}
                    >
                      <div
                        className="euiTableCellContent euiTableCellContent--overflowingContent"
                        style={{ padding: '8px', lineHeight: '24px', cursor: 'pointer' }}
                      >
                        <EuiFlexGroup gutterSize="none" alignItems="center">
                          <EuiFlexItem grow={false} style={{ paddingRight: '24px' }}>
                            <EuiRadio
                              onChange={() => {
                                setHighlightedLocation(undefined)
                                setNewPropertyRequested(true)
                              }}
                              checked={newPropertyRequested}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem data-test-id="create-new-property-radio" style={{ userSelect: 'none' }}>
                            Create new property
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {doesHighlightedLocationHaveAMembership && highlightedLocation?.id && (
                <>
                  <Callout type="script" title="As one of our members you will enjoy 15% off any work booked today" />
                  <EuiSpacer size="s" />
                </>
              )}

              <EuiSpacer />
              <EuiButton
                disabled={!highlightedLocation && !newPropertyRequested}
                onClick={() => (highlightedLocation ? onSelect(highlightedLocation) : onNew())}
                color="primary"
                fill
                data-test-id="select-property-button"
              >
                Select
              </EuiButton>
            </>
          ) : (
            <>
              <EuiSpacer />
              <EuiButton onClick={onNew} color="primary" fill>
                Create new property
              </EuiButton>
            </>
          ))}
      </>
    </>
  )
}

export interface CustomerLocationTableProps {
  selectedLocation: string | undefined
  locations: Location[]
  onSelect: (location: CustomerLocationFragment) => void
  loading: boolean
  additionalClassName?: string
  showMembership?: boolean // e.g customer is commercial so show memberships will be false
}

export const CustomerLocationTable = (props: CustomerLocationTableProps) => {
  const { locations, onSelect, loading, selectedLocation, additionalClassName, showMembership } = props

  const canViewMemberships = showMembership ?? true

  const columns: EuiBasicTableColumn<Location>[] = [
    {
      field: 'id',
      name: '',
      width: '40px',
      render: (id: string, location: Location) => (
        <EuiRadio checked={id === selectedLocation} onChange={() => onSelect(location)} />
      )
    },
    ...(canViewMemberships
      ? [
          {
            field: 'id',
            name: '',
            width: '40px',
            render: (id: string, location: Location) => {
              const locationMemberships: CustomerMembership[] = location?.memberships ?? []
              const currentMembership = locationMemberships?.find(
                (m) => m.status.status === MembershipStatusType.Active
              )

              if (!currentMembership) return <></>
              return (
                <EuiIcon
                  type={currentMembership.level === MembershipLevel.Premium ? PremiumMemberLogo : StandardMemberLogo}
                  style={{ marginRight: '6px' }}
                  size="l"
                />
              )
            }
          }
        ]
      : []),
    {
      field: 'formattedAddress',
      name: 'Address'
    }
  ]

  const onClickRow = (location: Location) => {
    onSelect(location)
  }

  return (
    <EuiBasicTable
      className={`basic-table--minimal ${additionalClassName}`}
      loading={loading}
      items={locations}
      columns={columns}
      data-test-subj="property-search-results-table"
      rowProps={(row) => ({ onClick: () => onClickRow(row), 'data-test-id': 'property-search-result-row' })}
      noItemsMessage={loading ? 'Loading...' : 'No properties found'}
    />
  )
}
