import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import EmailValidator from 'email-validator'
import { useEffect, useRef, useState } from 'react'
import { LoginForm } from '../login/login-form'
import '../static/css/user-email-form.css'

export interface UserEmailFormProps {
  onChange: (value: string) => void
}

export const UserEmailForm = ({ onChange }: UserEmailFormProps) => {
  const [email, setEmail] = useState('')
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const emailValid = email.toLowerCase().trim().length > 0 && EmailValidator.validate(email)

  const onChangeEmailValue = (value: string) => {
    setShowError(false)
    setEmail(value)
  }

  const handleContinue = () => {
    console.log('handleContinue', email)
    if (!emailValid) {
      setShowError(true)
      return
    }
    setLoading(true)
    setTimeout(() => {
      const formattedEmail = email.toLowerCase().trim()
      setLoading(false)
      onChange(formattedEmail)
    }, 1500)
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <LoginForm
      pageClassName="user-email-page"
      title="Welcome to FS Platform"
      icon={<EuiIcon size="xxl" type="logoSecurity" />}
      description="Streamlined platform for home services in Australia"
      footer={
        <>
          <EuiTitle size="xxs">
            <h3>Want to learn more?</h3>
          </EuiTitle>
          <EuiText size="s">View our website (coming soon)</EuiText>
          {/* <EuiLink  href="https://fsplatform.net" target="_blank" external={false}>
            View our website
          </EuiLink> */}
        </>
      }
    >
      {loading ? (
        <div style={{ margin: '12px 0' }}>
          <EuiLoadingSpinner size="xl" />
        </div>
      ) : (
        <form onSubmit={handleContinue} autoComplete="off">
          <EuiForm>
            <EuiFormRow label="Email address" error={!emailValid ? 'Invalid email address' : null}>
              <EuiFieldText
                inputRef={inputRef}
                autoComplete="off"
                isInvalid={showError && !emailValid}
                icon="email"
                value={email}
                onChange={(e) => onChangeEmailValue(e.target.value)}
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiButton fill onClick={handleContinue} fullWidth={true} type="submit">
              Continue
            </EuiButton>
          </EuiForm>
        </form>
      )}
    </LoginForm>
  )
}
