import { EuiAccordion, EuiHorizontalRule, EuiSpacer, EuiTitle } from '@elastic/eui'
import { makeHumanReadable } from '@falloncore/util'
import { MoneyUtils } from '@fallonsolutions/money'
import Dinero from 'dinero.js'
import { filter } from 'lodash-es'
import {
  Currency,
  InvoiceFragment,
  InvoiceMethod,
  InvoiceSource,
  InvoiceType,
  PaymentFragment,
  PaymentType,
  TransactionType
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import PriceSummaryRow from '../option-sheets/price-summary-row'
import { PaymentRow } from '../payments/payment-row'
import { PaymentTaskRow } from '../payments/payment-task-row'
import { SendPaymentLinkButton } from '../payments/send-payment-link-button'
import '../static/css/invoice.css'

interface InvoiceSummaryProps {
  invoice: InvoiceFragment
  startExpanded?: boolean
  payments?: PaymentFragment[]
  creditPayments?: PaymentFragment[]
}

// eslint-disable-next-line complexity
const InvoiceSummary = (props: InvoiceSummaryProps) => {
  const { invoice, payments, creditPayments } = props
  const startExpanded = props.startExpanded ?? false

  const refundPayments = filter(creditPayments, (creditPayment: any) => creditPayment.type === PaymentType.Refund)
  const credit = MoneyUtils.fromString(invoice?.credit?.total?.amount ?? '0.00')
  const refund = MoneyUtils.fromString(invoice?.refund?.total?.amount ?? '0.00')
  const userFragment = useAuthenticated().userFragment
  const canViewPaymentTasks = userFragment.features?.payByLink === true

  return (
    <div className="invoice-summary">
      {invoice.source === InvoiceSource.Platform && (
        <EuiAccordion
          id={`${invoice.id}-accordion`}
          buttonContent={
            <>
              <EuiTitle size="xs">
                <h3>{invoice.type === InvoiceType.CreditNote ? 'Credit Note' : 'Invoice'}</h3>
              </EuiTitle>
              {invoice.method === InvoiceMethod.Manual
                ? `Manual: ${invoice.manualInvoice?.reasonType ? makeHumanReadable(invoice.manualInvoice.reasonType) : 'No reason provided'}`
                : 'Option Sheet'}
            </>
          }
          initialIsOpen={startExpanded}
          paddingSize="s"
          arrowDisplay="right"
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
        >
          {invoice?.lineItems?.map((lineItem) => (
            <PriceSummaryRow key={lineItem.id} amount={lineItem.amount.total} label={lineItem.name} paddingRight="0" />
          ))}
          {invoice?.discounts?.map((lineItem) => (
            <PriceSummaryRow
              key={lineItem.id}
              amount={lineItem.amount.total}
              label={lineItem.name}
              negative={true}
              paddingRight="0"
            />
          ))}
          {invoice?.ancillaryLineItems?.map((lineItem) => (
            <PriceSummaryRow key={lineItem.id} amount={lineItem.amount.total} label={lineItem.name} paddingRight="0" />
          ))}
          {invoice?.fees?.map((lineItem) => (
            <PriceSummaryRow key={lineItem.id} amount={lineItem.amount.total} label={lineItem.name} paddingRight="0" />
          ))}
          {invoice?.adjustments?.map((lineItem) => (
            <PriceSummaryRow
              key={lineItem.id}
              amount={lineItem.amount.total}
              label={lineItem.name}
              negative={lineItem.transactionType === TransactionType.Credit}
              paddingRight="0"
            />
          ))}
          <EuiHorizontalRule />
        </EuiAccordion>
      )}

      {invoice?.totalAmount && (
        <PriceSummaryRow
          amount={invoice?.totalAmount.total}
          label="TOTAL (inc. GST)"
          currencySymbol={true}
          bold={true}
        />
      )}

      {invoice?.paid?.total?.amount && (
        <PriceSummaryRow amount={invoice?.paid?.total} label="Paid" currencySymbol={true} />
      )}
      {invoice?.credit?.total?.amount && credit.greaterThan(Dinero({ amount: 0, currency: Currency.AUD })) && (
        <PriceSummaryRow amount={invoice?.credit?.total} label="Credit" currencySymbol={true} />
      )}

      {invoice?.refund?.total?.amount && refund.greaterThan(Dinero({ amount: 0, currency: Currency.AUD })) && (
        <PriceSummaryRow amount={invoice?.refund?.total} label="Refund" currencySymbol={true} />
      )}

      {invoice?.balance?.amount && (
        <PriceSummaryRow amount={invoice?.balance} label="Balance" currencySymbol={true} nonZeroHighlight={true} />
      )}

      {invoice.source === InvoiceSource.Platform && payments && (
        <div>
          <EuiHorizontalRule />
          <div style={{ padding: '12px' }}>
            <EuiTitle size="xs">
              <h3>{payments && payments.length > 0 ? 'Payments' : 'No payments'} </h3>
            </EuiTitle>
            <EuiSpacer size="s" />
          </div>
          <div style={{ padding: '12px' }}>
            <EuiSpacer size="s" />
            {payments?.map((payment) => (
              <div key={payment.id}>
                <PaymentRow payment={payment} />
                <EuiHorizontalRule margin="xs" />
              </div>
            ))}
          </div>
        </div>
      )}

      {invoice.source === InvoiceSource.Platform &&
        canViewPaymentTasks &&
        invoice.paymentTasks?.map((paymentTask) => (
          <div key={paymentTask.id}>
            <EuiHorizontalRule />
            <div style={{ padding: '12px' }}>
              <EuiTitle size="xs">
                <h3>Pay by Link </h3>
              </EuiTitle>
            </div>
            <div style={{ padding: '12px' }}>
              <EuiSpacer size="s" />

              <div key={paymentTask.id}>
                <PaymentTaskRow paymentTask={paymentTask} />
              </div>
              <SendPaymentLinkButton paymentTask={paymentTask} invoice={invoice} />
            </div>
          </div>
        ))}

      {invoice.source === InvoiceSource.Platform && creditPayments && (
        <div>
          <EuiHorizontalRule />
          <div style={{ padding: '12px' }}>
            <EuiTitle size="xs">
              <h3>{creditPayments && creditPayments?.length > 0 ? 'Credits' : 'No credits'}</h3>
            </EuiTitle>
            <EuiSpacer size="s" />
            {creditPayments?.map((payment) => (
              <div key={payment.id}>
                <PaymentRow payment={payment} />
                <EuiHorizontalRule margin="xs" />
              </div>
            ))}
          </div>
        </div>
      )}

      {invoice.source === InvoiceSource.Platform && refundPayments && (
        <div>
          <EuiHorizontalRule />
          <div style={{ padding: '12px' }}>
            <EuiTitle size="xs">
              <h3>{refundPayments && refundPayments?.length > 0 ? 'Refunds' : ''}</h3>
            </EuiTitle>
            <EuiSpacer size="s" />
            {refundPayments?.map((payment) => (
              <div key={payment.id}>
                <PaymentRow payment={payment} />
                <EuiHorizontalRule margin="xs" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default InvoiceSummary
