import { BrowserRouter } from 'react-router'
import { QueryParamProvider } from 'use-query-params'
import AuthenticationProvider from '../auth/authentication-provider'
import AuthenticationSessionExpiry from '../auth/authentication-session-expiry'
import AuthorizationProvider from '../auth/authorization-provider'
import ThemeSelector from '../theme/theme-selector'
import { AppContextProvider } from './app-context'
import { FeatureToggleProvider } from './feature-toggle-service'
import GraphQLProvider from './graphql-provider'
import { MixpanelProvider } from './mixpanel-provider'
import AuthenticatedRoutesWrapper from './routes-authenticated-wrapper'
import UnauthenticatedRoutesWrapper from './routes-unauthenticated-wrapper'
import { SentryProvider } from './sentry'
import { ReactRouter7Adapter } from './use-query-params-react-router-7-adapter'
import { UserEmailProvider } from './user-email-provider'

const App = () => {
  return (
    <ThemeSelector>
      <UserEmailProvider>
        <AppContextProvider>
          <FeatureToggleProvider>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter7Adapter}>
                <MixpanelProvider>
                  <SentryProvider>
                    <AuthenticationProvider>
                      <AuthenticationSessionExpiry>
                        <UnauthenticatedRoutesWrapper>
                          <GraphQLProvider>
                            {/*<ConfigLoaderProvider>*/}
                            <AuthorizationProvider>
                              <AuthenticatedRoutesWrapper />
                            </AuthorizationProvider>
                            {/*</ConfigLoaderProvider>*/}
                          </GraphQLProvider>
                        </UnauthenticatedRoutesWrapper>
                      </AuthenticationSessionExpiry>
                    </AuthenticationProvider>
                  </SentryProvider>
                </MixpanelProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </FeatureToggleProvider>
        </AppContextProvider>
      </UserEmailProvider>
    </ThemeSelector>
  )
}

export default App
