import { EuiHeaderSection, EuiHeaderSectionItem, EuiLoadingSpinner } from '@elastic/eui'
import { openUrlInNewTab } from '../../common/open-in-new-tab'
import '../../static/css/active-interaction-message-bar.css'
import { useInteractionService } from '../interaction-service'
import { ActiveInteractionMessageBarView } from './active-interaction-message-bar-view'

export const ActiveInteractionMessageBar = () => {
  const { activeInteractionMessage, loading } = useInteractionService()

  const onClickBar = () => {
    if (activeInteractionMessage) {
      const url = `/interaction-message/${activeInteractionMessage.id}`
      openUrlInNewTab(url)
    }
  }

  const status = activeInteractionMessage?.status.status
  const statusClassName = status ? `active-interaction-message-bar__glow--status-${status.toLowerCase()}` : ''

  return (
    <EuiHeaderSection
      className={`active-interaction-message-bar-wrapper ${
        activeInteractionMessage ? 'active-interaction-message-bar-wrapper--active' : ''
      }`}
      grow={true}
      style={{
        marginLeft: '24px',
        marginRight: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        borderLeft: '1px solid #65666A',
        borderRight: '1px solid #65666A'
      }}
      onClick={onClickBar}
    >
      <EuiHeaderSectionItem className="active-interaction-message-bar">
        <div className="active-interaction-message-bar__content">
          {activeInteractionMessage ? (
            <ActiveInteractionMessageBarView interactionMessage={activeInteractionMessage} loading={loading} />
          ) : loading ? (
            <EuiLoadingSpinner size="s" />
          ) : (
            <></>
          )}
        </div>
        <div className={`active-interaction-message-bar__glow ${statusClassName}`} />
      </EuiHeaderSectionItem>
    </EuiHeaderSection>
  )
}
