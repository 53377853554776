import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { compact } from 'lodash-es'
import { EnvBanner } from '../nav/env-banner'
import '../static/css/login-form.css'

export interface LoginFormProps {
  title: string
  description: string
  icon: React.ReactNode
  pageClassName?: string
  footer?: React.ReactNode
  children: React.ReactNode
}

export const LoginForm = ({ title, icon, description, footer, pageClassName, children }: LoginFormProps) => {
  return (
    <div className={compact(['login-page', pageClassName]).join(' ')}>
      <EnvBanner fixed={false} />
      <EuiFlexGroup justifyContent="center" direction="column" style={{ minHeight: '75vh' }}>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false}>
          <EuiEmptyPrompt
            style={{ minWidth: '420px', minHeight: '320px' }}
            className="login-form"
            icon={icon}
            title={<h2>{title}</h2>}
            body={<p>{description}</p>}
            actions={children}
            footer={footer}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
    </div>
  )
}
