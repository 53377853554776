import { EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import { ISODate } from '@fallonsolutions/scalar'
import { compact, first, orderBy, take } from 'lodash-es'
import { useState } from 'react'
import { AppointmentStatusType, JobFragment, JobType, UserFragment } from '../api/generated-types'
import { CreateAuditForm } from '../audit/create-audit-form'
import UserLink from '../users/user-link'
import { JobAuditLabel } from './job-audit-label'
import { JobSummaryTimelineVisit } from './job-summary-timeline-visit'

// TODO: how to get this dynamically for tenant?
const jobAuditTemplateId = 'd53ccf37-2f8e-4207-b66d-4da3645c82b9'

interface JobSummaryTimelineProps {
  job: JobFragment
}

export const JobSummaryTimeline = (props: JobSummaryTimelineProps) => {
  const { job } = props

  const [showJobAuditModal, setShowJobAuditModal] = useState(false)
  const [showAllAppointments, setShowAllAppointments] = useState(false)

  const isProjectJob = [
    JobType.Maintenance,
    JobType.None,
    JobType.Callback,
    JobType.Warranty,
    JobType.Parts,
    JobType.Project
  ].includes(job.type ?? JobType.None)

  const appointments = orderBy(compact(job.appointments ?? []), ['scheduled.from'], ['asc']).filter(
    (a) =>
      ![
        AppointmentStatusType.Cancelled,
        AppointmentStatusType.None,
        AppointmentStatusType.Abandoned,
        AppointmentStatusType.Aborted
      ].includes(a.status?.status ?? AppointmentStatusType.None)
  )
  const firstAppointment = !isProjectJob ? first(appointments) : undefined
  const followOnAppointments = take(!isProjectJob ? appointments.slice(1) : appointments, showAllAppointments ? 100 : 3)
  const hiddenAppointmentCount = appointments.length - followOnAppointments.length - (firstAppointment ? 1 : 0)

  return (
    <div
      style={{
        background: '#F7F8FC',
        minHeight: '120px',
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '8px 16px 16px',
        borderTop: '1px solid #D4DAE6',
        borderBottom: '1px solid #D4DAE6'
      }}
    >
      <EuiFlexGroup alignItems="flexStart" gutterSize="xl">
        {/* <EuiFlexItem grow={false}>
          <JobReferrer jobId={job.id} referrer={job.referrer} />
        </EuiFlexItem> */}

        {job.creator && (
          <EuiFlexItem grow={false} style={{ minWidth: '220px' }}>
            <div className="small-label">BOOKING</div>
            <UserLink
              subtitle={[formatDateStr(job.created)]}
              subtitleSize="s"
              avatarSize="l"
              user={job.creator as UserFragment | null | undefined}
            />
          </EuiFlexItem>
        )}

        {firstAppointment && (
          <EuiFlexItem grow={false} style={{ minWidth: '220px' }}>
            <div className="small-label">FIRST VISIT</div>
            <JobSummaryTimelineVisit appointment={firstAppointment} />
          </EuiFlexItem>
        )}

        {followOnAppointments.length > 0 && (
          <EuiFlexItem grow={false}>
            <div className="small-label">FOLLOW ON VISITS</div>
            <EuiFlexGroup alignItems="flexStart" wrap={true}>
              {followOnAppointments.map((appointment) => (
                <EuiFlexItem grow={false} key={appointment.id}>
                  <JobSummaryTimelineVisit appointment={appointment} />
                </EuiFlexItem>
              ))}
              <EuiFlexItem grow={true}>
                {hiddenAppointmentCount > 0 && (
                  <EuiLink onClick={() => setShowAllAppointments(true)}>+{hiddenAppointmentCount} more</EuiLink>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}

        <EuiFlexItem grow={false}>
          <JobAuditLabel onClick={() => setShowJobAuditModal(true)} job={job} />
        </EuiFlexItem>
      </EuiFlexGroup>

      {showJobAuditModal && (
        <CreateAuditForm
          auditTemplateId={jobAuditTemplateId}
          jobId={job.id}
          onClose={() => setShowJobAuditModal(false)}
        />
      )}
    </div>
  )
}

const formatDateStr = (date: ISODate | undefined | null) => {
  const dateObj = date ? dateUtils.fromISO(date) : undefined
  return compact([
    dateObj?.toFormat(dateConfig.luxonFormat.time).toLowerCase() ?? undefined,
    dateObj?.toFormat(dateConfig.luxonFormat.fullDate) ?? ''
  ]).join(' ')
}
