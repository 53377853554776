import { EuiButton, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, EuiText } from '@elastic/eui'
import { EnquiryAbortReason, Membership } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { decamelise } from '../../common/utils'
import { useAppConfig } from '../../config/app-config-provider'
import { AllAction, useDecisionPoint } from '../../decision-point/use-decision-point'
import { useWorkflow } from '../helpers/workflow-provider'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { CustomerMembershipQuestion } from './customer-qualifications/customer-membership'

export enum ContactRole {
  HomeOwner = 'Home owner',
  Tenant = 'Tenant',
  Commercial = 'Commercial',
  RealEstateAgent = 'Real estate agent',
  Other = 'Other'
}

export interface CustomerQualificationActionInput extends BaseActionInput {
  customerId?: string
  isNewCustomer?: boolean
}

export interface CustomerQualificationActionResult extends BaseActionResult {
  contactRole?: ContactRole
  existingCustomer?: boolean
  existingMember?: boolean
  payingForWork?: boolean
  customerMemberships?: Membership[]
}

export const CustomerQualificationAction = (
  props: WorkflowActionProps<CustomerQualificationActionInput, CustomerQualificationActionResult>
) => {
  const workflowContext = useWorkflow()
  const tenant = useAppConfig().tenant
  const newMemberEligibleForFreeTravelFee = tenant?.modules?.booking?.freeCustomerTravelFeeFlag === true

  const { onUpdate, result, input } = props
  const { customerId, isNewCustomer } = input

  const { actionCompleted, contactRole, existingCustomer, existingMember, payingForWork } = result ?? {
    existingCustomer: !isNewCustomer
  }

  const { canChangeEnquiryCustomerMembership } = useDecisionPoint(AllAction.ChangeEnquiryCustomerMembership)
  const canUserChangeCustomerMembership = !!canChangeEnquiryCustomerMembership().ok
  const value = actionCompleted
    ? `${decamelise(contactRole)} / ${existingCustomer === true ? 'Existing customer' : 'New customer'} / ${
        existingMember === true ? 'Member' : 'Not a member'
      }`
    : undefined

  const isThePaymentQuestionRelevant = !!contactRole && [ContactRole.Tenant, ContactRole.Other].includes(contactRole)

  const completed =
    contactRole !== undefined &&
    (isThePaymentQuestionRelevant === false || payingForWork === true) &&
    existingCustomer !== undefined &&
    (contactRole !== ContactRole.HomeOwner || existingCustomer !== undefined) &&
    canUserChangeCustomerMembership
      ? true // check to make sure they selected whether a customer is a member or not
      : true
  //(contactRole !== ContactRole.HomeOwner || existingMember !== undefined || existingCustomer === false)

  const setContactRole = (contactRole: ContactRole | undefined) =>
    onUpdate({ ...result, contactRole, existingMember: undefined, existingCustomer: undefined })
  const setPayingForWork = (payingForWork: boolean | undefined) => onUpdate({ ...result, payingForWork })
  const setExistingCustomer = (existingCustomer: boolean | undefined) =>
    onUpdate({ ...result, existingCustomer, existingMember: undefined })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })
  return (
    <WorkflowAction
      title="Property ownership"
      value={value}
      onClickChange={() => onUpdate({ ...result, actionCompleted: false })}
      editable={input.editable}
    >
      <Callout type="script" title="Just to confirm, you are the home owner, renting or is this for a business?" />
      <EuiKeyPadMenu className="fs-keypadmenu">
        <EuiKeyPadMenuItem
          id="contact-role-homeowner"
          isSelected={contactRole === ContactRole.HomeOwner}
          label="Home owner"
          onClick={() => setContactRole(ContactRole.HomeOwner)}
        >
          <span className="fs-keypadmenu--element">Hm</span>
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="contact-role-tenant"
          isSelected={contactRole === ContactRole.Tenant}
          label="Renting (tenant)"
          onClick={() => setContactRole(ContactRole.Tenant)}
        >
          <span className="fs-keypadmenu--element">Te</span>
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="contact-role-commercial"
          isSelected={contactRole === ContactRole.Commercial}
          label="Commercial / Business"
          onClick={() => setContactRole(ContactRole.Commercial)}
        >
          <span className="fs-keypadmenu--element">Co</span>
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="contact-role-realestate"
          isSelected={contactRole === ContactRole.RealEstateAgent}
          label="Real estate agent"
          onClick={() => setContactRole(ContactRole.RealEstateAgent)}
        >
          <span className="fs-keypadmenu--element">Re</span>
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="contact-role-other"
          isSelected={contactRole === ContactRole.Other}
          label="Other"
          onClick={() => setContactRole(ContactRole.Other)}
        >
          <span className="fs-keypadmenu--element">Ot</span>
        </EuiKeyPadMenuItem>
      </EuiKeyPadMenu>

      {isThePaymentQuestionRelevant && (
        <>
          <EuiSpacer />
          <Callout
            type="script"
            title="That’s not a problem, just to confirm, will you be the one paying for this work?"
          />
          <EuiKeyPadMenu className="fs-keypadmenu">
            <EuiKeyPadMenuItem
              id="paying-for-work-yes"
              isSelected={payingForWork === true}
              label="Paying"
              onClick={() => setPayingForWork(true)}
            >
              <EuiIcon type="check" size="xl" />
            </EuiKeyPadMenuItem>
            <EuiKeyPadMenuItem
              id="paying-for-work-no"
              isSelected={payingForWork === false}
              label="Not paying"
              onClick={() => setPayingForWork(false)}
            >
              <EuiIcon type="cross" size="xl" />
            </EuiKeyPadMenuItem>
          </EuiKeyPadMenu>
        </>
      )}

      {contactRole && isThePaymentQuestionRelevant && payingForWork === false && (
        <>
          <EuiSpacer />
          <Callout type="script">
            <EuiText>
              {contactRole === ContactRole.Tenant && (
                <>
                  <div>What I'll get you to do is contact your real estate.</div>
                  <EuiSpacer size="m" />
                </>
              )}
              <div>In order for us to book the job in, we need to speak with whomever is paying for the work.</div>
            </EuiText>
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() => workflowContext.abort({ abortReason: EnquiryAbortReason.CustomerNotWillingToPay })}
          >
            End call
          </EuiButton>
        </>
      )}

      {contactRole && (!isThePaymentQuestionRelevant || payingForWork === true) && (
        <>
          <EuiSpacer />

          <Callout
            type="script"
            title={
              customerId
                ? 'It looks like you have used Fallon Solutions previously - is this correct?'
                : `It looks like this is the first time you've called Fallon Solutions - is that correct?`
            }
          />
          <EuiKeyPadMenu className="fs-keypadmenu">
            <EuiKeyPadMenuItem
              id="existing-customer-yes"
              isSelected={existingCustomer === true}
              label="Existing customer"
              onClick={() => setExistingCustomer(true)}
            >
              <span className="fs-keypadmenu--element">Ext</span>
            </EuiKeyPadMenuItem>
            <EuiKeyPadMenuItem
              id="existing-customer-no"
              isSelected={existingCustomer === false}
              label="New customer"
              onClick={() => setExistingCustomer(false)}
            >
              <span className="fs-keypadmenu--element">New</span>
            </EuiKeyPadMenuItem>
          </EuiKeyPadMenu>
          {/* <EuiSpacer />
          <Callout type="note" title={customerSinceTip} /> */}

          <EuiSpacer />
          {canUserChangeCustomerMembership && (
            <>
              <CustomerMembershipQuestion
                customerQualification={result}
                excludeQuestion={existingCustomer !== true}
                updateCustomer={onUpdate}
                alternativeDisplay={<Callout type="script" title="Welcome back!" />}
                displayMemberQuestion={contactRole === ContactRole.HomeOwner}
              />
            </>
          )}

          {existingCustomer === false && newMemberEligibleForFreeTravelFee && (
            <>
              <EuiSpacer />
              <Callout
                type="script"
                title="Well thank you for calling Fallon Solutions, as a new customer, you will receive a special discount that I will discuss later"
              />
            </>
          )}
        </>
      )}

      {completed && (
        <>
          <EuiSpacer size="s" />
          <EuiButton onClick={() => setActionCompleted(true)}>Next</EuiButton>
          <EuiSpacer />
        </>
      )}
    </WorkflowAction>
  )
}
