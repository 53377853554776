import { BookingWindow } from '@fallonsolutions/appointment'
import { dateConfig } from '@fallonsolutions/date'
import { getTravelFeeId } from '@fallonsolutions/travel-fee'
import { JobTopicType } from '@fallonsolutions/types'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import {
  CustomerType,
  Discount,
  JobType,
  Membership,
  MembershipLevel,
  MembershipStatusType,
  ServiceArea,
  TradeType
} from '../../api/generated-types'
import { formatPhone } from '../../common/phone'
import { BookingWrapUpActionResult } from '../actions/wrap-up/action-booking-wrapup'
import { ContactRole } from '../actions/action-customer-qualification'
import { JobClassificationActionResult } from '../actions/action-job-classification-types'
import { SewerIssue, StormwaterIssue } from '../topics/drain/action-topic-plumbing-drain-model'
import { TravelFee, getTravelFee } from './travel-fee'
import { YesNoValue } from '../topics/common/common-enums'

export interface InternalNotesProps {
  contactRole: ContactRole
  customerType: CustomerType
  jobType: JobType
  trade: TradeType
  jobTopic?: JobTopicType
  existingCustomer?: boolean
  existingMember?: boolean
  contactPhone: string
  bookingWindow?: BookingWindow
  customerInfo?: {
    name: string
  }
  issueType?: StormwaterIssue | SewerIssue
  afterHours?: boolean
  attributeID?: string
  jobClassification?: JobClassificationActionResult
  serviceArea?: ServiceArea
  customerMembership?: Membership
  // topic?: Record<string, any> // no way to strongly type this
  bookingWrapUp?: BookingWrapUpActionResult
  memberships?: Membership[]
  appliedDiscounts?: Discount[]
}

const newLineChars = '\n\n'

export const generateInternalNotes = (props: InternalNotesProps): string => {
  const {
    trade,
    contactRole,
    existingMember,
    jobType,
    jobTopic,
    issueType,
    jobClassification,
    serviceArea,
    appliedDiscounts,
    bookingWrapUp
  } = props

  const afterHours = props.afterHours ?? false

  const travelFeeId = getTravelFeeId({
    tradeType: trade,
    topicType: jobTopic ?? JobTopicType.Other,
    jobType,
    customerType: contactRole === ContactRole.HomeOwner ? CustomerType.Domestic : CustomerType.Commercial,
    afterHours: props.afterHours ?? false
  })
  console.log('travelFeeId', travelFeeId)
  // TODO: migrate to using getTravelFee query to get notes for internal notes generator

  const travelFee = getTravelFee({
    contactRole,
    existingMember: existingMember === true,
    afterHours,
    trade,
    jobType,
    jobTopicReference: jobTopic ?? JobTopicType.Other,
    issueType,
    travelFeeWaived: jobClassification?.requirements?.travelFeeWaived ?? false,
    serviceArea,
    categoryType: jobClassification?.category,
    topic: jobClassification?.topic
  })
  const { doYouHaveADog } = bookingWrapUp ?? {}

  return compact([
    jobRow(props),
    contactRow(props),
    bookingRow(props),
    covidProtectionRow(props),
    ...(travelFee ? [feesRow(travelFee)] : ['Travel fee not required']),
    discountsRow(appliedDiscounts),
    specialNotesRow(props),
    ...(doYouHaveADog === YesNoValue.Yes ? [dogOnSiteRow()] : [])
  ]).join(newLineChars)
}

const dogOnSiteRow = () => {
  return 'Dog on site'
}

const discountsRow = (discounts?: Discount[]): string | undefined => {
  if (!discounts || discounts.length === 0) {
    return undefined
  }
  return `Customer is eligible for the following discounts:  ${discounts.map((d) => d.name).join(', ')}`
}

const covidProtectionRow = (props: InternalNotesProps): string => {
  const { bookingWrapUp } = props
  const { requireCovidProtections } = bookingWrapUp ?? {}
  if (!requireCovidProtections) {
    return ''
  }
  return `Covid precautions required: ${requireCovidProtections}`
}

const jobTypeLabel = (jobType: JobType): string => (jobType === JobType.Callback ? 'Revisit' : jobType)

const jobRow = ({
  contactRole,
  existingCustomer,
  existingMember,
  jobType,
  trade,
  customerMembership
}: InternalNotesProps): string => {
  const foundMembershipInSystem =
    !!customerMembership && customerMembership.status.status === MembershipStatusType.Active

  const foundMembershipInSystemStatement = `Current: ${
    customerMembership?.level === MembershipLevel.Premium ? 'Black plan' : 'Red plan'
  }, expiry: ${
    DateTime.fromISO(customerMembership?.expiryDate).toFormat(dateConfig.format.date) ?? '<membership expiry>'
  }`

  const defaultMembershipStatement = 'Current: <membership level>, expiry: <membership expiry>'

  const existingMemberStatement = foundMembershipInSystem
    ? foundMembershipInSystemStatement
    : defaultMembershipStatement

  return [
    contactRole,
    `${trade} ${jobTypeLabel(jobType)}`,
    existingCustomer ? 'Existing customer' : 'New customer',
    ...(existingCustomer && contactRole === ContactRole.HomeOwner
      ? [existingMember ? existingMemberStatement : 'Non-member']
      : [])
  ].join(', ')
}

const contactRow = (props: InternalNotesProps): string => `Call ${formatPhone(props.contactPhone)} when on the way`

const bookingRow = ({ bookingWindow }: InternalNotesProps): string => {
  if (!bookingWindow) {
    return `Booking to be advised`
  }
  if (!bookingWindow.startTime) return `Booking to be advised`
  return `Booked @ ${bookingWindow.label}`
}

const feesRow = (travelFee: TravelFee): string => {
  return [
    `${travelFee.amount} ${travelFee.label}`,
    ...(travelFee.additionalNotes?.length ? [travelFee.additionalNotes] : [])
  ].join(newLineChars)
}

const specialNotesRow = ({
  contactRole,
  trade,
  jobType,
  jobTopic,
  memberships
}: InternalNotesProps): string | undefined => {
  if (jobType === JobType.Callback) {
    return [
      'Call on the way, advised customer job chargeable unless covered under Fallon or Supplier warranty. Fallon attended site **install date**.',
      'Customer has agreed to pay for any non-warranty work'
    ].join(newLineChars)
  }
  const isCustomerExistingBasedOnMemberships =
    (memberships?.filter((m) => m.status.status === MembershipStatusType.Active) ?? []).length > 0
  // display comp membership if hvac repair or appliances and the customer isn't a member
  const displayComplimentaryHPP =
    contactRole === ContactRole.HomeOwner &&
    (trade === TradeType.Appliances || (trade === TradeType.HVAC && jobTopic === JobTopicType.HVACRepair)) &&
    !isCustomerExistingBasedOnMemberships

  const hvacMembershipExtension =
    isCustomerExistingBasedOnMemberships &&
    contactRole === ContactRole.HomeOwner &&
    (trade === TradeType.Appliances || (trade === TradeType.HVAC && jobTopic === JobTopicType.HVACRepair))

  return compact([
    ...(contactRole !== ContactRole.HomeOwner ? ['Payment on day, no account setup'] : []),
    ...(displayComplimentaryHPP ? ['Complimentary HPP as per promo advised'] : []),
    ...(hvacMembershipExtension ? ['customer eligible for 1 year complimentary membership extension'] : [])
  ]).join(newLineChars)
}
