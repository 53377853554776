import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink, EuiRadio, EuiSpacer } from '@elastic/eui'
import { SearchContactCustomersQuery } from '../../api/generated-types'
import { useContactSearch } from './contact-search-hook'
import { ContactSearchResultsTable } from './contact-search-results-table'

export interface ContactSearchResultsProps {
  allowCreate: boolean
  loading: boolean
  searching: boolean
  data?: SearchContactCustomersQuery
  onSelectionConfirmed?: () => void
}

export const ContactSearchResults = (props: ContactSearchResultsProps) => {
  const { allowCreate, loading, data, searching, onSelectionConfirmed } = props
  const [searchState, dispatch] = useContactSearch()

  const onCreateNew = () => {
    return dispatch({ type: 'select_create_new_contact', payload: { createNew: true } })
  }

  const onReset = () => {
    return dispatch({ type: 'reset' })
  }

  return (
    <>
      {(searching || data) && (
        <>
          <EuiLink onClick={onReset} color="primary" aria-label="Reset search">
            <EuiIcon type="cross" /> Reset
          </EuiLink>
          <EuiSpacer size="s" />
        </>
      )}
      {searching && <ContactSearchResultsTable data={data} loading={loading} />}
      {!loading && data && (
        <>
          {allowCreate && (
            <>
              <div style={{ padding: '12px 6px', fontSize: '15px', color: '#999', fontWeight: 300 }}>OR</div>
              <div className="basic-table--minimal">
                <div className="basic-table--minimal euiTable euiTable--responsive">
                  <div className="euiTableRow euiTableRow-isClickable">
                    <div className="euiTableRowCell" onClick={onCreateNew}>
                      <div
                        className="euiTableCellContent euiTableCellContent--overflowingContent"
                        style={{ padding: '8px', lineHeight: '24px', cursor: 'pointer' }}
                      >
                        <EuiFlexGroup gutterSize="none" alignItems="center">
                          <EuiFlexItem grow={false} style={{ marginRight: '12px' }} key="newcontact">
                            <EuiRadio
                              aria-label="Create new contact"
                              onChange={onCreateNew}
                              checked={searchState.createNew}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem style={{ userSelect: 'none' }} key="createcontact">
                            Create new contact
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <EuiSpacer size="s" />
          <EuiButton
            aria-label="Select contact"
            disabled={!searchState.selectedContact && !searchState.createNew}
            onClick={() => {
              onSelectionConfirmed && onSelectionConfirmed()
            }}
            color="primary"
            fill
          >
            Select
          </EuiButton>
          <EuiSpacer size="s" />
        </>
      )}
    </>
  )
}
