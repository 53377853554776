import { EuiAccordion, EuiCard, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { EnquiryWebFormFragment, WebFormFieldType } from '../api/generated-types'
import { formatPhone } from '../common/phone'
import { useContactSearch } from '../contacts/contact-search/contact-search-hook'
import { ContactSearchFields } from '../contacts/contact-search/contact-search-model'

export interface EnquiryWebFormViewProps {
  webForm: EnquiryWebFormFragment | undefined | null
}

const fromRequest = (webForm?: EnquiryWebFormFragment | null): Partial<ContactSearchFields> => {
  if (!webForm?.request) {
    return {}
  }
  //by templateTypeId?
  const body = JSON.parse(webForm.request?.body)

  const contact = body.input.contact
  const street = compact([contact.address?.street, contact.address?.street2]).join(' ')
  // TODO: use suburbId to get suburb name for contact search fields input
  // const suburbId = contact.address?.suburbId
  return { ...contact, street: street ?? '', suburb: '' }
}

export const EnquiryWebFormView = (props: EnquiryWebFormViewProps) => {
  const { webForm } = props
  const webFormFieldGroups = webForm?.webFormFieldGroups

  const [open, setOpen] = useState<'closed' | 'open'>('open')
  const oppositeState = useMemo(() => (open === 'open' ? 'closed' : 'open'), [open])

  const [, dispatch] = useContactSearch()

  const setContactSearchFields = () => {
    const searchFields = fromRequest(webForm)
    dispatch({
      type: 'set_search_fields',
      payload: {
        searchFields,
        ...(webForm?.contact?.id && { selectedContact: webForm?.contact })
      }
    })
  }

  useEffect(() => {
    setContactSearchFields()
  }, [])

  return (
    <>
      {!!webFormFieldGroups && webFormFieldGroups.length > 0 && (
        <>
          <EuiAccordion
            title="Web form"
            id={v4()}
            buttonContent="Web form"
            forceState={open}
            onToggle={() => setOpen(oppositeState)}
          >
            <EuiSpacer />
            <EuiFlexGroup direction="row" gutterSize="l" justifyContent="flexStart" alignItems="flexStart">
              {webFormFieldGroups.map((group, index) => (
                <EuiCard key={index} title={group.name} textAlign="left">
                  <EuiFlexItem key={index} grow={false}>
                    <EuiText size="s">
                      <EuiFlexGroup direction="column" gutterSize="xs">
                        <EuiFlexItem grow={false}>
                          {group.fields.map((field, index) => {
                            return (
                              <span key={index}>
                                {field.key}: {renderWebFormFieldValue(field.value, field.type)} <br></br>
                              </span>
                            )
                          })}
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiText>
                  </EuiFlexItem>
                </EuiCard>
              ))}
            </EuiFlexGroup>
            <EuiSpacer />
          </EuiAccordion>
        </>
      )}
    </>
  )
}

const renderWebFormFieldValue = (value: string, type: WebFormFieldType): string | ReactElement => {
  switch (type) {
    case WebFormFieldType.Email:
    case WebFormFieldType.Text:
      return value
    case WebFormFieldType.Phone:
      return formatPhone(value)
    case WebFormFieldType.DateTime: {
      return DateTime.fromISO(value).setZone(dateConfig.defaultTimezone).toFormat(dateConfig.luxonFormat.dateAndTime)
    }
    default:
      return value
  }
}
