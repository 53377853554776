import { EuiButtonEmpty, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui'
import { compact, keys, map, omit } from 'lodash-es'
import { useState } from 'react'
import { JobStatusType } from '../api/generated-types'
import { makeHumanReadable } from '../common/utils'

interface JobStatusComboBoxProps {
  status: JobStatusType[]
  onChange: (status: JobStatusType[]) => void
}

const JobStatusComboBox = (props: JobStatusComboBoxProps) => {
  const { status, onChange } = props

  const [enabled, setEnabled] = useState(status.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const validStatusTypes = keys(
    omit(JobStatusType, [JobStatusType.CancelledWork, JobStatusType.WaitingForParts, JobStatusType.None])
  )
  const allStatusTypes = map(validStatusTypes, (statusType) => {
    return {
      id: statusType,
      label: makeHumanReadable(statusType)
    }
  })

  const renderOption = (option: any, searchValue: any, contentClassName: any) => {
    return (
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (status.length <= 0) {
      setEnabled(false)
    }
  }

  const selectedOptions = status.map((s) => ({
    id: s,
    label: s
  }))

  return (
    <form autoComplete="off">
      <EuiComboBox
        inputRef={setComboInput}
        placeholder="Status"
        options={allStatusTypes}
        selectedOptions={selectedOptions}
        onChange={(values) => onChange(compact(values.map((v) => v.id)) as JobStatusType[])}
        isClearable={true}
        renderOption={renderOption}
        rowHeight={40}
        isLoading={allStatusTypes.length <= 0}
        style={{ minWidth: '200px' }}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label="job-status"
        data-test-subj="job-status"
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Status
        </EuiButtonEmpty>
      )}
    </form>
  )
}

export default JobStatusComboBox
