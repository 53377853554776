import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { JobFragment, JobStatusType } from '@fallonsolutions/types'
import { useAuthenticated } from '../auth/authenticated-context'

export interface JobAuditLabelProps {
  onClick: () => void
  job: JobFragment
}

export const JobAuditLabel = (props: JobAuditLabelProps) => {
  const { onClick, job } = props

  const { userFragment } = useAuthenticated()

  const canUserCreateAudit = userFragment.permissions?.canCreateJobAudit === true

  const isWaitingForReview = job.status?.status !== JobStatusType.Complete

  return (
    <>
      {canUserCreateAudit && isWaitingForReview ? (
        <>
          <div className="small-label truncate">Supervisor review</div>
          <EuiFlexGroup gutterSize="none" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty size="s" onClick={onClick} color="primary">
                Review
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
