import React, { useContext } from 'react'
import createPersistedState from '../use-persisted-state'
import { UserEmailForm } from './user-email-form'

export interface PersistedUser {
  email?: string
}

const usePersistedUser = createPersistedState<PersistedUser>('user')

interface MixpanelContextProviderProps {
  children: React.ReactNode
}

export interface UserEmailContextValue {
  userEmail: string | undefined
  clearUserEmail: () => void
}

export const UserEmailContext = React.createContext<UserEmailContextValue>({
  userEmail: undefined,
  clearUserEmail: () => {}
})

export const UserEmailProvider = (props: MixpanelContextProviderProps) => {
  const [user, setUser] = usePersistedUser({})

  const clearUserEmail = () => setUser({})
  const onChangeUserEmail = (email: string) => setUser({ email })

  const value: UserEmailContextValue = { userEmail: user.email, clearUserEmail }

  return user.email ? (
    <UserEmailContext.Provider value={value}>{props.children}</UserEmailContext.Provider>
  ) : (
    <UserEmailForm onChange={onChangeUserEmail} />
  )
}

export const useUserEmail = () => useContext(UserEmailContext)
