import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiDatePicker,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormLabel,
  EuiFormRow,
  EuiHorizontalRule,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiRadioGroup,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { MoneyUtils } from '@fallonsolutions/money'
import { map, omit, sortBy } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  CreateCreditNoteDocument,
  CreateCreditNoteInput,
  CreateLineItemInput,
  CreditNoteApplicationType,
  InvoiceFragment,
  InvoiceLineItemType,
  ManualInvoiceReasonType
} from '../api/generated-types'
import { OperationSuccess } from '../common/operation-success'
import { getLabelForManualInvoiceReason } from './manual-invoice-helpers'

interface CreateCreditNoteProps {
  invoice: InvoiceFragment
  closeModal: () => void
}

export const CreateCreditNote = (props: CreateCreditNoteProps) => {
  const { invoice, closeModal } = props
  const [createCreditNote, { data, error, loading }] = useMutation(CreateCreditNoteDocument, {
    refetchQueries: ['GetJob', 'GetInvoiceWithPayments', 'GetInvoice'],
    awaitRefetchQueries: true,
    errorPolicy: 'none'
  })

  const totalAmount = omit(invoice.balance, ['__typename']) ?? { amount: '0.00' }
  const balance = MoneyUtils.fromString(invoice.balance?.amount ?? '0.00')

  const initialCustomerEmail =
    invoice.customer?.billingContact?.detail?.email ?? invoice.customer?.mainContact?.detail?.email ?? ''

  const [id] = useState(uuidv4())
  const [amount, setAmount] = useState(totalAmount?.amount ?? '0.00')
  const [customerEmail, setCustomerEmail] = useState(initialCustomerEmail)
  const [sendCreditNote, setSendCreditNote] = useState(false)
  const [printCreditNote, setPrintCreditNote] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [description, setDescription] = useState('Credit')
  const [postDate, setPostDate] = useState<Moment>(moment().tz(dateConfig.defaultTimezone))
  const [editPostDate, setEditPostDate] = useState(false)
  const [manualInvoiceReasonType, setManualInvoiceReasonType] = useState<ManualInvoiceReasonType | undefined>(undefined)

  const onChangeAmount = (value: string) => setAmount(value)

  const validateAmount = (): string[] => {
    if (!amount) {
      return ['An amount is required']
    }
    try {
      const money = MoneyUtils.fromString(amount)
      if (money.greaterThan(balance)) {
        return ['Amount cannot exceed invoice balance']
      }
      return []
    } catch (err) {
      console.error(err)
      return ['Invalid amount value']
    }
  }

  const convertAmount = (value: string) => {
    try {
      const money = MoneyUtils.fromString(value)
      return money
    } catch (err) {
      console.error(err)
      return MoneyUtils.fromString('0.00')
    }
  }

  const handlePostDateChange = (newDate: Moment) => setPostDate(newDate)

  const onBeginEditing = () => setShowErrors(false)

  const invoiceTypes = [ManualInvoiceReasonType.NoOptionSheet]
  const manualInvoiceReasonTypeOptions = sortBy(
    map(omit(ManualInvoiceReasonType, invoiceTypes), (value) => ({
      id: value,
      label: getLabelForManualInvoiceReason(value)
    })),
    (option) => (option.id === ManualInvoiceReasonType.Other ? 'z' : option.label)
  )

  const amountErrors = validateAmount()
  const reasonErrors = !manualInvoiceReasonType ? ['A reason is required'] : []
  const errors = [...amountErrors, ...reasonErrors]
  const form = (
    <EuiForm
      style={{ minHeight: '350px' }}
      isInvalid={showErrors ? errors.length > 0 : false}
      error={showErrors ? errors : []}
    >
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormLabel>Invoice</EuiFormLabel>
          <EuiText>{invoice.number}</EuiText>
        </EuiFlexItem>
        <EuiFlexItem style={{ textAlign: 'right', minWidth: '200px' }}>
          <EuiFormLabel>
            Post date
            {!editPostDate && (
              <EuiLink onClick={() => setEditPostDate(true)} style={{ fontSize: '11px', marginLeft: '6px' }}>
                Change
              </EuiLink>
            )}
          </EuiFormLabel>
          {editPostDate ? (
            <EuiDatePicker
              selected={postDate}
              onChange={handlePostDateChange}
              dateFormat="ddd D MMM YYYY"
              popoverPlacement="downRight"
              maxDate={moment().tz(dateConfig.defaultTimezone)}
              minDate={moment().tz(dateConfig.defaultTimezone).subtract(45, 'days')}
            />
          ) : (
            <EuiText>{postDate.format(dateConfig.format.fullDate)}</EuiText>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <EuiFormRow label="Description">
        <EuiFieldText value={description} onChange={(e) => setDescription(e.target.value)}></EuiFieldText>
      </EuiFormRow>
      <EuiSpacer size="s" />
      <EuiFormRow label="Amount" isInvalid={showErrors && amountErrors.length > 0}>
        <EuiFieldNumber
          placeholder="Enter amount"
          value={amount === '0.00' ? '' : amount}
          onChange={(e) => onChangeAmount(e.target.value)}
          isInvalid={validateAmount().length > 0}
          style={{ width: '140px', textAlign: 'left' }}
          step="any"
          min={0}
          max={110000}
          onKeyDown={onBeginEditing}
        />
      </EuiFormRow>
      <EuiSpacer size="l" />

      <EuiFormRow label="Select reason for manual invoice" isInvalid={showErrors && reasonErrors.length > 0}>
        <EuiRadioGroup
          options={manualInvoiceReasonTypeOptions}
          idSelected={manualInvoiceReasonType}
          onChange={(value) => setManualInvoiceReasonType(value as ManualInvoiceReasonType)}
          name="company"
          data-test-subj="company-selector"
          aria-label="company-selector"
        />
      </EuiFormRow>

      <EuiHorizontalRule />

      <EuiCheckbox
        id="send-credit-note"
        label="Send credit note email to customer"
        checked={sendCreditNote}
        onChange={(e) => setSendCreditNote(e.target.checked)}
        disabled={loading}
      />
      {sendCreditNote ? (
        <EuiFieldText value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
      ) : (
        <div>
          <EuiSpacer size="s" />
          <EuiCheckbox
            id="print-credit-note"
            label="Print and mail credit note to customer"
            checked={printCreditNote}
            onChange={(e) => setPrintCreditNote(e.target.checked)}
            disabled={loading}
          />
        </div>
      )}
      {error && (
        <div>
          <EuiCallOut size="s" title={error?.message} color="danger" iconType="alert" />
        </div>
      )}
    </EuiForm>
  )

  const success = <OperationSuccess message={`Successfully created credit note`} />

  const handleCreateCreditNote = async () => {
    const jobId = invoice.job?.id
    if (!jobId) {
      return
    }
    if (validateAmount().length > 0 || !manualInvoiceReasonType) {
      setShowErrors(true)
      return
    }

    const money = convertAmount(amount)
    const moneyInput = MoneyUtils.moneyToObject(money)

    const creditLineItem = (): CreateLineItemInput => {
      return {
        name: description,
        type: InvoiceLineItemType.LineItem,
        amount: moneyInput
      }
    }

    const input: CreateCreditNoteInput = {
      id,
      job: jobId,
      lineItems: [creditLineItem()],
      discounts: [],
      invoice: invoice.id,
      fees: [],
      apply: CreditNoteApplicationType.Invoice,
      sendCreditNote: sendCreditNote,
      printAndMailCreditNote: !sendCreditNote && printCreditNote,
      customerEmail: customerEmail,
      postDate: postDate.toISOString(true),
      manualInvoice: {
        reasonType: manualInvoiceReasonType
      }
    }
    try {
      await createCreditNote({ variables: { input } })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Create Credit Note</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <div>{data?.createCreditNote?.id ? success : form}</div>
        </EuiModalBody>
        {!data ? (
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
              Cancel
            </EuiButtonEmpty>
            <EuiButton onClick={handleCreateCreditNote} fill isLoading={loading} isDisabled={loading}>
              {loading ? 'Creating credit note' : 'Create credit note'}
            </EuiButton>
          </EuiModalFooter>
        ) : (
          <EuiButtonEmpty onClick={closeModal}>Done</EuiButtonEmpty>
        )}
      </EuiModal>
    </EuiOverlayMask>
  )
}
