import { EuiFlexGroup, EuiLink, EuiText } from '@elastic/eui'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import { PaymentProvider, PaymentTaskLinkFragment } from '@fallonsolutions/types'
import { useAppConfig } from '../config/app-config-provider'
import FatZebraIcon from '../static/images/fatzebra-icon.svg?react'

export interface PaymentTaskLinkProps {
  paymentTask: PaymentTaskLinkFragment
}

export const PaymentTaskLink = ({ paymentTask }: PaymentTaskLinkProps) => {
  const appConfig = useAppConfig()

  const customerAppBaseUrl = appConfig.tenant.customerApp?.baseUrl
  const paymentLink = customerAppBaseUrl ? `${customerAppBaseUrl}/invoice/${paymentTask.invoiceId}` : undefined

  const icon = iconForPaymentProvider(paymentTask.provider)
  const dateStr = dateUtils.fromISO(paymentTask.updated).toFormat(dateConfig.luxonFormat.fullDateAndTime)
  return (
    <EuiFlexGroup gutterSize="s" alignItems="flexStart">
      {icon}
      <div>
        {paymentLink ? (
          <EuiLink href={paymentLink} target="_blank">
            Payment link {paymentTask.reference}
          </EuiLink>
        ) : (
          <EuiText>Payment link {paymentTask.reference}</EuiText>
        )}
        <div style={{ paddingTop: '3px', color: '#727888', fontSize: '12px' }}>updated {dateStr}</div>
      </div>
    </EuiFlexGroup>
  )
}

const iconForPaymentProvider = (provider: PaymentProvider) => {
  switch (provider) {
    case PaymentProvider.FatZebra:
      return <FatZebraIcon style={{ height: '24px', width: '24px', marginTop: '4px' }} />
    default:
      return null
  }
}
