import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink, EuiSpacer } from '@elastic/eui'
import { DateTime } from 'luxon'
import {
  Money,
  PaymentApplicationType,
  PaymentFragment,
  PaymentMethod,
  PaymentSource,
  PaymentStatus,
  PaymentType
} from '../api/generated-types'
import { dateConfig } from '../common/date-config-luxon'
import { formatMoney } from '../common/money'
import { useAppConfig } from '../config/app-config-provider'
import '../static/css/invoice.css'
import FatZebraIcon from '../static/images/fatzebra-icon.svg?react'

interface PaymentRowProps {
  payment: PaymentFragment
}

export const PaymentRow = (props: PaymentRowProps) => {
  const appConfig = useAppConfig()

  const { payment } = props

  let postDate: string
  if (payment.postDate) {
    postDate = DateTime.fromISO(payment.postDate).toFormat(dateConfig.fullDate)
  } else {
    postDate = 'Unknown post date'
  }

  const getPaymentApplicationDetails = (payment: PaymentFragment): string => {
    if (payment.type === PaymentType.CreditNote) {
      const invoiceNumbers = (payment.applications ?? []).map((application) =>
        application.type === PaymentApplicationType.InvoicePaymentApplication ? application?.invoice?.number : ''
      )
      return ` for ${invoiceNumbers.join(', ')} applied from ${payment.reference}`
    } else {
      return ` for ${payment.refundInvoice?.number ?? ''}`
    }
  }

  let methodString: string
  let detailString = ''
  switch (payment.method) {
    case PaymentMethod.Cash:
      methodString = 'Cash payment'
      break
    case PaymentMethod.Cheque:
      methodString = 'Cheque payment'
      break
    case PaymentMethod.DebitCard:
      methodString = 'Debit card payment'
      break
    case PaymentMethod.CreditCard:
      methodString = 'Credit card payment'
      break
    case PaymentMethod.EFT:
      methodString = 'EFT payment'
      break
    case PaymentMethod.OpenPay:
      methodString = 'OpenPay payment'
      break
    case PaymentMethod.Finance:
      methodString = 'Finance payment'
      break
    case PaymentMethod.IntegraPay:
      methodString = 'IntegraPay payment'
      break
    case PaymentMethod.StaffDeduction:
      methodString = 'Staff deduction'
      break
    default:
      switch (payment.type) {
        case PaymentType.CreditNote:
          methodString = 'Credit note'
          detailString = getPaymentApplicationDetails(payment)
          break
        case PaymentType.Refund:
          methodString = 'Customer refund'
          detailString = getPaymentApplicationDetails(payment)
          break
        case PaymentType.Void:
          methodString = 'Void'
          break
        default:
          methodString = 'Unknown'
      }
  }

  const voidedString = payment.status === PaymentStatus.Voided ? 'Voided ' : ''
  const amountString = (amount?: Money | null): string => {
    return amount ? formatMoney(amount, { currencySymbol: true }) : 'unknown amount'
  }
  const inlineStyle: any = { paddingRight: '12px' }
  if (payment.status === PaymentStatus.Voided) {
    inlineStyle['textDecoration'] = 'line-through'
  }

  const icon = iconForPaymentSource(payment.source ?? PaymentSource.Platform)
  const customerAppBaseUrl = appConfig.tenant.customerApp?.baseUrl
  const paymentUrl =
    payment.source === PaymentSource.FatZebra && customerAppBaseUrl
      ? `${customerAppBaseUrl}/payment/${payment.id}`
      : undefined

  return (
    <div className="payment-row">
      <EuiFlexGroup gutterSize="xs" alignItems="flexStart">
        <EuiFlexItem grow={false} style={{ paddingTop: '3px' }}>
          {icon}
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <div>
            <a href={`/payments/${payment.id}`} target="_blank" rel="noopener noreferrer">
              {`${payment.reference}: `}
              {voidedString}
              {methodString}
              {detailString}
            </a>
          </div>
          <div className="light-text">{postDate}</div>
          {paymentUrl && (
            <div>
              <EuiSpacer size="s" />
              <EuiLink href={paymentUrl} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 400 }}>
                View receipt
              </EuiLink>
            </div>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
          <span className="price-summary__row__value" style={inlineStyle}>
            {amountString(payment.amount)}
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

const iconForPaymentSource = (source: PaymentSource) => {
  switch (source) {
    case PaymentSource.FatZebra:
      return <FatZebraIcon style={{ height: '24px', width: '24px', marginTop: '4px' }} />
    default:
      return <EuiIcon type="bullseye" />
  }
}
