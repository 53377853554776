import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiSpacer } from '@elastic/eui'
import { toSuburbSelectionFromComponents } from '@fallonsolutions/suburb'
import { useEffect, useState } from 'react'
import { ContactInput, InteractionMessageFragment } from '../../api/generated-types'
import { HorizontalRule } from '../../common/horizontal-rule'
import { formatPhone } from '../../common/phone'
import { ContactCardAddressDisplay, ContactDetailCard } from '../../contacts/contact-detail-card'
import CustomerCard from '../../customers/customer-card'
import { OnChangeContactProps } from '../../workflow/workflow-interaction-message-model'
import { ContactCapture } from './interaction-message-detail-contact-capture'

export interface InteractionMessageDetailContactProps {
  interactionMessage: InteractionMessageFragment
  canCapture?: boolean
  onChangeContact?: ({ customer, contactId, contactInput }: OnChangeContactProps) => Promise<void>
}

export const InteractionMessageDetailContact = ({
  interactionMessage,
  canCapture = false,
  onChangeContact
}: InteractionMessageDetailContactProps) => {
  const customer = interactionMessage.customer
  const contact = interactionMessage.contact

  const [capturing, setCapturing] = useState<boolean>(canCapture)

  const address = customer?.mainContact?.detail?.address ?? contact?.address
  const suburb =
    address && address.suburbId && address.suburb && address.state && address.postcode
      ? toSuburbSelectionFromComponents({
          id: address.suburbId,
          suburb: address.suburb,
          state: address.state,
          postcode: address.postcode
        })
      : contact?.suburb
        ? contact.suburb
        : undefined

  const input = {
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    email: contact?.email ?? '',
    phone: formatPhone(contact?.phone) ?? '',
    suburb
  }
  const handleOnComplete = (contactInput: ContactInput) => {
    setCapturing(!capturing)
    //on allow valid changes, validate phone and suburb
    onChangeContact &&
      onChangeContact({ customer: undefined, contactId: undefined, contactInput: onlyValidContactInput(contactInput) })
  }

  const handleOnCancel = () => {
    setCapturing(false)
  }

  useEffect(() => {
    if (capturing && interactionMessage?.customer) {
      setCapturing(false)
    }
  }, [interactionMessage?.customer, capturing])

  const isValid = (contactInput: ContactInput) => {
    //validate phone, partial phone isnt ok
    //validate email, empty is ok, partial is not
    return true
  }

  const onlyValidContactInput = (contactInput: ContactInput) => {
    return {
      firstName: contactInput.firstName,
      lastName: contactInput.lastName,
      email: contactInput.email && contactInput.email?.length > 0 ? contactInput.email : undefined,
      phone: contactInput.phone,
      ...(contactInput.suburb && { suburb: { id: contactInput.suburb.id, label: contactInput.suburb?.label } })
    }
  }

  return (
    <div style={{ width: '350px' }}>
      {capturing ? (
        <EuiPanel hasShadow={true}>
          <ContactCapture
            contactInput={input}
            completed={!capturing}
            onComplete={handleOnComplete}
            onCancel={handleOnCancel}
          />
        </EuiPanel>
      ) : (
        <>
          {customer ? (
            <>
              <CustomerCard
                customer={customer}
                showReference={true}
                addressDisplay={ContactCardAddressDisplay.SuburbLabel}
              />
              <HorizontalRule />
              <EuiFlexGroup>
                <EuiFlexItem grow={true} />
                <EuiFlexItem grow={false}>
                  <EuiLink href={`/customers/${customer?.id}`} target="_blank">
                    Open
                  </EuiLink>
                </EuiFlexItem>
                <EuiFlexItem grow={true}></EuiFlexItem>
              </EuiFlexGroup>
            </>
          ) : contact ? (
            <EuiPanel hasShadow={false} hasBorder={true}>
              <ContactDetailCard detail={contact} addressDisplay={ContactCardAddressDisplay.SuburbLabel} />
              <EuiSpacer size="s" />
              <HorizontalRule />
              <EuiFlexGroup>
                <EuiFlexItem grow={true} />
                <EuiFlexItem grow={false}>
                  {canCapture ? <EuiLink onClick={() => setCapturing(true)}>Change</EuiLink> : null}
                </EuiFlexItem>
                <EuiFlexItem grow={true} />
              </EuiFlexGroup>
            </EuiPanel>
          ) : (
            <>No contact info</>
          )}
        </>
      )}
    </div>
  )
}
