import { useQuery } from '@apollo/client'
import { createContext, useContext } from 'react'
import { AppConfigFragment, GetAppConfigDocument } from '../api/generated-types'
import { LoadingSpinner } from '../loading/loading-spinner'

export const AppConfigContext = createContext<AppConfigFragment | undefined>(undefined)

export const useAppConfig = () => {
  const context = useContext(AppConfigContext)
  if (!context) {
    throw new Error('needs AppConfig as provider')
  }
  return context
}

interface AppConfigProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const AppConfigProvider = (props: AppConfigProviderProps) => {
  const { loading, data, error } = useQuery(GetAppConfigDocument, {
    fetchPolicy: 'cache-and-network'
  })
  const appConfig = data?.getAppConfig

  return loading ? (
    <LoadingSpinner message="Fetching config" />
  ) : error ? (
    <div>Failed to load config</div>
  ) : !appConfig ? (
    <div>Failed to load config</div>
  ) : (
    <AppConfigContext.Provider value={appConfig}>{props.children}</AppConfigContext.Provider>
  )
}
